import { useContext } from "react"
import { useTranslation } from "react-i18next"

import ConfigurationContext from "../../../../context/ConfigurationContext"
import ViewClass from "../../../../helpers/configuration/view"
import { getActiveViews } from "../../../../helpers/configuration/views"
import Tooltip from "../../../Tooltip/Tooltip"

import style from "./ViewSwitcher.module.scss"

const ViewSwitcher = () => {
  const { t } = useTranslation()
  const context = useContext(ConfigurationContext)

  const { currentView, switchView, views } = context

  if (!views) {
    return ""
  }

  const handleViewChange = id => {
    switchView(id)
  }

  const activeViewsNumber = () => {
    const activeViews = getActiveViews(views)

    return Object.entries(activeViews).length
  }

  if (activeViewsNumber() <= 1) {
    return ""
  }

  return (
    <ul className={style.viewSwitcher}>
      {Object.entries(views).map(item => {
        const [id, viewData] = item

        const viewObj = ViewClass(id, viewData)
        let name = viewObj.getName()
        name = t(name)

        const isCurrent = id === currentView
        const isActive = viewObj.isActive()

        if (!isActive) {
          return ""
        }

        return (
          <li key={`view-${id}`} data-for={`view-${id}`} data-tip={name}>
            <Tooltip
              place="top"
              identificador={`view-${id}`}
              view={"preview"}
            />
            <button
              type="button"
              className={[style.buttons, isCurrent ? style.active : ""].join(
                " "
              )}
              onClick={() => handleViewChange(id)}
            >
              {name} {isCurrent ? "(active)" : ""}
            </button>
          </li>
        )
      })}
    </ul>
  )
}

export default ViewSwitcher
