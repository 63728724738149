import React, { useState, createContext, useEffect } from "react"

import { getWithAuth } from "../helpers/general"

// Create Context Object
export const CasUserContext = createContext({})

// Create a provider for components to consume and subscribe to changes
export const CasUserContextProvider = props => {
  const [user, setUser] = useState(null)
  const [withAuth, setWithAuth] = useState(null)

  useEffect(() => {
    getWithAuth().then(value => setWithAuth(value))
  }, [])

  if (withAuth === null) {
    return ""
  }

  return (
    <CasUserContext.Provider value={{ user, setUser, withAuth }}>
      {props.children}
    </CasUserContext.Provider>
  )
}
