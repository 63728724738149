import PropTypes from "prop-types"

import { Field, useFormikContext } from "formik"

import { updateAllDependentValues } from "../../../../helpers/configuration/resets/resetDependencies"
import ErrorMessage from "../ErrorMessage/ErrorMessage"

import styles from "./Switch.module.scss"

const Switch = props => {
  const { title, name, options } = props

  const formik = useFormikContext()
  const { values, handleChange, setValues } = formik

  const handleChangeField = event => {
    handleChange(event)

    const value = event.target.value

    setValues(updateAllDependentValues(name, value, formik))
  }

  const fieldValue = values[name]

  const getIsChecked = value => {
    if (!value) {
      return false
    }

    return fieldValue === value
  }

  const optionNumber = 2

  if (
    !options ||
    options.length < optionNumber ||
    options.every(o => o.hidden)
  ) {
    return ""
  }

  // There should only be 2 options, take only the first two.
  let maxOptions = [...options]
  maxOptions.length = optionNumber

  return (
    <div className={styles.contentSwitch}>
      {title && <p className={styles.titleField}>{title}</p>}

      <div className={styles.content}>
        {maxOptions.map(option => {
          const {
            text: optionText,
            value: optionValue,
            disabled: optionDisabled = false,
          } = option

          const isChecked = getIsChecked(optionValue)
          return (
            <label
              key={`field-${name}-option-${optionText}-${optionValue}`}
              className={[
                styles.label,
                optionDisabled ? styles.disabled : " ",
                isChecked ? styles.checked : " ",
              ].join(" ")}
            >
              {optionText}

              <Field
                className={styles.input}
                disabled={optionDisabled}
                type="radio"
                name={name}
                value={optionValue}
                onChange={handleChangeField}
              />
            </label>
          )
        })}
      </div>

      <ErrorMessage name={name} />
    </div>
  )
}

export default Switch

Switch.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ),
}

Switch.defaultProps = {
  title: "",
  name: "",
  options: [],
}
