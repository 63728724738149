import { useLocation } from "react-router-dom"
import { matchPath } from 'react-router';

const useCCR = () => {
  const location = useLocation()

  // the best way I could find to check if the given path is the custom one
  const isCCR = !!matchPath(
    "/:locale/ccr/:SCHEMA/:CCR",
    location.pathname
  )

  return { isCCR }
}

export default useCCR
