import i18n from "i18next"

const validatePuerta = values => {
  const { t } = i18n

  const errors = {}

  if (!values.CCAB_PUERTA_MODELO) {
    errors.CCAB_PUERTA_MODELO = t("Please select an option.")
  }

  if (!values.CCAB_PUERTA_MATERIAL) {
    errors.CCAB_PUERTA_MATERIAL = t("Please select an option.")
  }

  return errors
}

export default validatePuerta
