const initialPersonalData = {
  personal_data: {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    country: "",
    province: "",
    company: "",
    file_send: "yes",
    observations: "",
    personal_data_rights: false,
    send_more_information: false,
  },
}

export default initialPersonalData
