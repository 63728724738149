import i18n from "i18next"

import { hasDoorOnSide } from "../../../helpers-shared/configuration/utils"

const validateParedes = values => {
  const { t } = i18n

  const errors = {}

  if (!values.CCAB_PARED_IZDA_ACABADO && !hasDoorOnSide("IZQUIERDA", values)) {
    errors.CCAB_PARED_IZDA_ACABADO = t("Please select an option.")
  }

  if (!values.CCAB_PARED_DCHA_ACABADO && !hasDoorOnSide("DERECHA", values)) {
    errors.CCAB_PARED_DCHA_ACABADO = t("Please select an option.")
  }

  if (!values.CCAB_PARED_FRONT_ACABADO && !hasDoorOnSide("FRONTAL", values)) {
    errors.CCAB_PARED_FRONT_ACABADO = t("Please select an option.")
  }

  return errors
}

export default validateParedes
