import React from "react"
import { Helmet } from "react-helmet-async"

import PropTypes from "prop-types"

import { getSiteTitle } from "../../helpers/general"

const siteTitle = getSiteTitle()

const SEO = ({ title, description, withSuffix }) => {
  return (
    <Helmet>
      <title>
        {title}
        {withSuffix ? ` | ${siteTitle}` : ""}
      </title>
      {description && <meta name="description" content={description} />}
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  withSuffix: PropTypes.bool,
}

SEO.defaultProps = {
  title: siteTitle,
  description: "",
  withSuffix: true,
}
