import i18n from "i18next"

const validateSuelo = values => {
  const { t } = i18n

  const errors = {}

  if (!values.CCAB_SUELO_MATERIAL) {
    errors.CCAB_SUELO_MATERIAL = t("Please select an option.")
  }

  if (!values.CCAB_SUELO_ZOCALOS) {
    errors.CCAB_SUELO_ZOCALOS = t("Please select an option.")
  }

  return errors
}

export default validateSuelo
