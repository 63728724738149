import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import Select, { components } from "react-select"

import { getEnabledLanguages, getSortedLanguages } from "../../helpers/general"
import useScroll from "../../hooks/use-Scroll"
import locales from "../../locales"

import styles from "./LanguageSwitcher.module.scss"

let enabledLanguages = getEnabledLanguages(locales)
enabledLanguages = getSortedLanguages(enabledLanguages)

const LanguageSwitcher = () => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleChange = option => {
    const { value } = option

    const pathnameSplit = location.pathname.split(`/${i18n.language}/`)
    const newPathname =
      pathnameSplit.length > 1 ? `/${value}/${pathnameSplit[1]}` : `/${value}`

    i18n.changeLanguage(value)
    navigate(newPathname)
  }
  const y = useScroll()
  const CaretDownIcon = () => {
    return (
      <span className={[styles.arrow].join(" ")}>
        <svg
          className={styles.arrowLeft}
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7 7L1 1" stroke="#000000" strokeWidth="2"></path>
        </svg>
        <svg
          className={styles.arrowRight}
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7 1L1 7" stroke="#000000" strokeWidth="2"></path>
        </svg>
      </span>
    )
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    )
  }
  const options =
    enabledLanguages && Object.keys(enabledLanguages)?.length > 0
      ? Object.keys(enabledLanguages).map(lng => {
          return { value: lng, label: enabledLanguages[lng].nativeName }
        })
      : null

  const currentLanguageName =
    enabledLanguages?.[i18n.language]?.nativeName ?? "-"

  const onMenuOpen = () => {
    setIsMenuOpen(true)
  }
  const onMenuClose = () => setIsMenuOpen(false)

  const isScrollingSmallDevice = y > 2 && window.innerWidth < 993

  if (!options || options.length <= 0) {
    return ""
  }

  return (
    <Select
      placeholder={currentLanguageName}
      components={{ DropdownIndicator }}
      defaultValue={i18n.language}
      onChange={handleChange}
      options={options}
      className={[
        styles.select,
        isMenuOpen ? styles.menuOpen : " ",
        isScrollingSmallDevice ? styles.scrolling : " ",
      ].join(" ")}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      isSearchable={false}
    />
  )
}

export default LanguageSwitcher
