function ViewClass(id, variableData) {
  const obj = Object.create(variableData)
  obj.ID = id

  function getName() {
    if (!obj.NOMBRE || !obj.ID) {
      return null
    }

    const nombre = obj.NOMBRE ?? obj.ID

    return nombre
  }

  function isActive() {
    return obj.ACTIVO ?? true
  }

  return { getName, isActive }
}

export default ViewClass
