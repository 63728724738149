import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { CasUserContext } from "../context/CasUserContext"
import allowedUsernames from "../data/allowed-usernames"
import { getCasEndpoint, isInList } from "../helpers/general"

const useCasTokens = () => {
  const { i18n } = useTranslation()
  let navigate = useNavigate()
  const casUserContext = useContext(CasUserContext)

  const [isLoading, setIsLoading] = useState(false)
  const [casEndpoint, setCasEndpoint] = useState(null)

  useEffect(() => {
    getCasEndpoint()
      .then(data => {
        setCasEndpoint(data)
      })
      .catch(error => console.error(error))
  }, [])

  function attemptCasLogin() {
    if (!casEndpoint) {
      return
    }

    const loginUrl = getLoginUrl(casEndpoint)

    const ticket = getParamFromCurrentUrl("ticket")
    if (!ticket) {
      window.location.href = loginUrl
      return
    }

    const decodedJWT = parseJwt(ticket)
    if (!decodedJWT) {
      setIsLoading(false)
      navigate(`/${i18n.language}/not-authorized`, { replace: true })
      return
    }

    if (isJwtExpired(decodedJWT)) {
      window.location.href = loginUrl
      return
    }

    const user = decodedJWT.username || null

    if (!user || !isUserAllowed(allowedUsernames, user)) {
      setIsLoading(false)
      navigate(`/${i18n.language}/not-authorized`, { replace: true })
      return
    }

    casUserContext.setUser(user)
    setIsLoading(false)
    navigate(window.location.pathname, {
      replace: true,
    })
  }

  return { isLoading, attemptCasLogin }
}

export default useCasTokens

const isJwtExpired = decodedToken => {
  let isJwtExpired = false

  const { exp } = decodedToken
  const currentTime = new Date().getTime() / 1000

  if (currentTime > exp) {
    isJwtExpired = true
  }

  return isJwtExpired
}

const parseJwt = token => {
  try {
    if (typeof token !== "string" || !token) {
      throw new Error("Invalid token provided")
    }

    return JSON.parse(atob(token.split(".")[1]))
  } catch (e) {
    return null
  }
}

const isUserAllowed = (allowedList = [], userName = "") => {
  return isInList(allowedList, userName)
}

const getParamFromCurrentUrl = param => {
  return new URL(window.location.href).searchParams.get(param)
}

const getLoginUrl = casEndpoint => {
  const currentUrl = window.location.href

  return `https://${casEndpoint}/cas/login?service=${currentUrl}&status=in_process`
}
