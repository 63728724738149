import { useTranslation } from "react-i18next"

import styles from "./RotateWarning.module.scss"

const RotateWarning = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.rotateWarning}>
      <p>
        {t("Please turn your device vertically to use the configuration tool.")}
      </p>
    </div>
  )
}

export default RotateWarning
