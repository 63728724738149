import PropTypes from "prop-types"

import { Field, useFormikContext } from "formik"

import {
  getValueForCheckbox,
  updateAllDependentValues,
} from "../../../../helpers/configuration/resets/resetDependencies"
import ErrorMessage from "../ErrorMessage/ErrorMessage"

import style from "./OpcionesInline.module.scss"

const OpcionesInline = props => {
  const { title, name, options, multiselect } = props

  const formik = useFormikContext()
  const { values, handleChange, setValues } = formik

  const handleChangeField = event => {
    handleChange(event)

    const value = multiselect
      ? getValueForCheckbox(event, values)
      : event.target.value

    setValues(updateAllDependentValues(name, value, formik))
  }

  const fieldValue = values[name]

  const getIsChecked = value => {
    if (!value) {
      return false
    }

    if (multiselect) {
      return fieldValue.includes(value)
    }

    return fieldValue === value
  }

  if (!options || options.length <= 0 || options.every(o => o.hidden)) {
    return ""
  }

  return (
    <div className={style.contentOptions}>
      <div className={style.content}>
        <p className={style.titulo}>{title}</p>
        <ul className={style.options}>
          {options.map(option => {
            const {
              text: optionText,
              value: optionValue,
              disabled: optionDisabled = false,
              hidden: optionHidden = false,
            } = option

            if (optionHidden) {
              return ""
            }

            const isChecked = getIsChecked(optionValue)
            const isDisabled = !option.active || optionDisabled

            return (
              <li key={`field-${name}-option-${optionText}-${optionValue}`}>
                <label
                  className={[
                    style.label,
                    isDisabled ? style.disabled : " ",
                    isChecked ? style.checked : "",
                  ].join(" ")}
                >
                  <span
                    className={[
                      style.checkmark,
                      !multiselect ? style.radio : " ",
                    ].join(" ")}
                  ></span>
                  <span className={style.labeltext}>{optionText}</span>

                  {multiselect ? (
                    <Field
                      className={style.input}
                      type="checkbox"
                      name={name}
                      value={optionValue}
                      disabled={isDisabled}
                      onChange={handleChangeField}
                    />
                  ) : (
                    <Field
                      className={style.input}
                      type="radio"
                      name={name}
                      value={optionValue}
                      disabled={isDisabled}
                      onChange={handleChangeField}
                    />
                  )}
                </label>
              </li>
            )
          })}
        </ul>
      </div>
      <ErrorMessage name={name} />
    </div>
  )
}

export default OpcionesInline

OpcionesInline.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
      disabled: PropTypes.bool,
      hidden: PropTypes.bool,
    })
  ),
  multiselect: PropTypes.bool,
}

OpcionesInline.defaultProps = {
  title: "",
  name: "",
  options: [],
  multiselect: false,
}
