import React from "react"

import PropTypes from "prop-types"

import ListTitle from "../ListTitle/ListTitle"
import Image from "../image/Image"
import SubTitle from "../subTitles/SubTitle"

import styles from "./Card.module.scss"

const Card = ({ data, uppercase, inSlider, hoverd, disabled, selected }) => {
  const { title, subtitle, images } = data
  const getClass = ({ hoverd, disabled, selected }) => {
    if (disabled) {
      return styles.disabled
    }
    if (selected) {
      return styles.selected
    }
    if (!hoverd) {
      return " "
    } else {
      return styles.nohover
    }
  }
  return (
    <div
      className={[
        styles.card,
        inSlider ? styles.inSlider : " ",
        getClass({ hoverd, disabled, selected }),
      ].join(" ")}
    >
      <div className={styles.inner}>
        <div className={styles.contentImg}>
          <Image images={[images]} alt="string" />
        </div>
        <div className={styles.textContent}>
          <ListTitle uppercase={uppercase}>{title}</ListTitle>
          {subtitle ? <SubTitle>{subtitle}</SubTitle> : ""}
        </div>
      </div>
    </div>
  )
}

export default Card
Card.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  }),
  uppercase: PropTypes.bool,
  hoverd: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
}

Card.defaultProps = {
  data: null,
  uppercase: false,
  inSlider: false,
  hoverd: false,
  disabled: false,
  selected: false,
}
