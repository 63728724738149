import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { getDefaultLanguage, getEnabledLanguages } from "../helpers/general"
import locales from "../locales"

const enabledLanguages = getEnabledLanguages(locales)
const defaultLanguage = getDefaultLanguage(locales)

const Index = () => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()

  const localeCodes = Object.keys(enabledLanguages)

  const locale = localeCodes.includes(i18n.language)
    ? i18n.language
    : defaultLanguage

  useEffect(() => {
    navigate(`/${locale}`)
  })

  return "Index"
}

export default Index
