import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import PropTypes from "prop-types"

import { AppContext } from "../../context/AppContext"
import HomeStepsContext from "../../context/HomeStepsContext"
import AreaClass from "../../helpers/configuration/area"
import {
  getHomeStepsAreas,
  getNextArea,
  getPrevArea,
} from "../../helpers/configuration/areas"
import initialValues from "../../helpers/configuration/initial-values"
import Boarding from "./Boarding/Boarding"
import Model from "./Model/Model"
import Preset from "./Preset/Preset"
import StepsHeader from "./StepsHeader/StepsHeader"

const HomeSteps = ({ startStep }) => {
  const appContext = useContext(AppContext)
  const { store } = appContext

  const { i18n } = useTranslation()

  const navigate = useNavigate()

  const [step, setStep] = useState(startStep)
  const [values, setValues] = useState(initialValues)

  // Scroll to top every time we change the step
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [step])

  useEffect(() => {
    if (hasAllValues(values)) {
      const getConfigureUrl = () => {
        const { CCAB_MODELO, CCAB_EMBARQUE, CCAB_AMBIENTE } = values

        return `/${i18n.language}/${CCAB_MODELO}/${CCAB_EMBARQUE}/configure/${CCAB_AMBIENTE}`
      }

      const configureUrl = getConfigureUrl()
      navigate(configureUrl, { state: { firstStepsValues: values } })
    }
  }, [values, navigate, i18n.language])

  const homeSteps = getHomeStepsAreas(store.categories)

  if (!homeSteps) {
    return ""
  }

  const steps = Object.entries(homeSteps)
    .map((item, index) => {
      const [areaId, areaData] = item
      const areaObj = AreaClass(areaId, areaData)
      const variable = areaObj.getFirstVariable()

      if (!variable) {
        return null
      }

      const [variableId] = variable

      return {
        number: index + 1,
        name: areaObj.getName(i18n.language),
        areaId: areaObj.getId(),
        key: variableId,
        canVisit: areaObj.isActive(),
      }
    })
    .filter(s => s)

  const stepsMax = steps.length

  const goToStep = number => {
    setStep(number)

    // Reset next steps when navigating
    resetNextSteps(number)
  }

  // Reset following steps of the step with number `number`
  const resetNextSteps = number => {
    if (!steps || steps.length <= 0) {
      return null
    }

    const nextSteps = steps.filter(s => s.number > number)

    for (const s of nextSteps) {
      setValues(prevValues => {
        return { ...prevValues, [s.key]: initialValues[s.key] }
      })
    }
  }

  const prevStep = () => {
    if (step === startStep) {
      return null
    }

    const currentStepArea = steps.find(item => item.number === step)
    const prevArea = getPrevArea(homeSteps, currentStepArea.areaId)
    const prevStep = steps.find(item => item.areaId === prevArea)

    goToStep(prevStep.number)
  }

  const nextStep = () => {
    if (step === stepsMax) {
      return null
    }

    const currentStepArea = steps.find(item => item.number === step)
    const nextArea = getNextArea(homeSteps, currentStepArea.areaId)
    const nextStep = steps.find(item => item.areaId === nextArea)

    goToStep(nextStep.number)
  }

  const setValue = value => {
    const newValues = { ...values, ...value }
    setValues(newValues)
  }

  const renderStep = () => {
    const stepArea = steps.find(item => item.number === step)
    switch (stepArea.areaId) {
      case "MODELO":
        return <Model />
      case "EMBARQUE":
        return <Boarding />
      case "PREDISENO":
        return <Preset />
      default:
        return ""
    }
  }

  const contextValues = {
    step,
    steps,
    allAreas: homeSteps,
    stepsMax,
    startStep,
    values,
    goToStep,
    prevStep,
    nextStep,
    setValue,
  }

  return (
    <HomeStepsContext.Provider value={contextValues}>
      <StepsHeader />
      {renderStep()}
    </HomeStepsContext.Provider>
  )
}

export default HomeSteps

HomeSteps.propTypes = {
  startStep: PropTypes.number,
}

HomeSteps.defaultProps = {
  startStep: 1,
}

const hasAllValues = values => {
  if (!values) {
    return false
  }

  const firstStepValues = [
    values["CCAB_MODELO"],
    values["CCAB_EMBARQUE"],
    values["CCAB_AMBIENTE"],
    values["CCAB_ACCESIBILIDAD"],
  ]

  return firstStepValues.every(v => v !== "")
}
