import { Buffer } from "buffer"

/**
 * Encode given item to base64.
 * @returns {*}
 */
const base64Encode = item => {
  return Buffer.from(item).toString("base64")
}

/**
 * Decode given item from base64.
 * @param item
 * @returns {*}
 */
const base64Decode = item => {
  return Buffer.from(item, "base64")
}

/**
 * Decode given string from base64 to string.
 * @param string
 * @returns {string}
 */
const base64DecodeToString = string => {
  return base64Decode(string).toString("ascii")
}

export { base64Encode, base64Decode, base64DecodeToString }
