import { useTranslation } from "react-i18next"

import AreaClass from "../../../helpers/configuration/area"
import { getCmsAssetsUrl } from "../../../helpers/general"
import useScroll from "../../../hooks/use-Scroll"
import Svg from "../../Svg/Svg"
import AreaListItem from "../AreaListItem/AreaListItem"

import style from "./AreaList.module.scss"

const assetsUrl = getCmsAssetsUrl()

const AreaList = ({ areas }) => {
  const { i18n } = useTranslation()
  const y = useScroll()
  const vh = window.innerHeight
  document.documentElement.style.setProperty("--scroll", `${y}px`)

  document.documentElement.style.setProperty("--vh", `${vh}px`)

  if (!areas) {
    return ""
  }

  return (
    <ul className={[style.areaList, y > 2 ? style.isScrolling : " "].join(" ")}>
      {Object.entries(areas)?.length > 0 &&
        Object.entries(areas).map(area => {
          const [id, data] = area
          const areaObj = AreaClass(id, data)

          return (
            <AreaListItem
              key={id}
              areaId={id}
              icon={<Svg src={areaObj.getImage(assetsUrl)} />}
              name={areaObj.getName(i18n.language)}
              active={areaObj.isActive()}
            />
          )
        })}
    </ul>
  )
}

export default AreaList
