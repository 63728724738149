import i18n from "i18next"

const validateProtecciones = values => {
  const { t } = i18n

  const errors = {}

  if (
    values.CCAB_PROTECCIONES_TIPO === "BANDAS" &&
    !values.CCAB_PROTECCIONES_MATERIAL
  ) {
    errors.CCAB_PROTECCIONES_MATERIAL = t("Please select an option.")
  }

  if (
    values.CCAB_PROTECCIONES_TIPO === "MULTIPLES_PASAMANOS" &&
    !values.CCAB_PROTECCIONES_MODELO
  ) {
    errors.CCAB_PROTECCIONES_MODELO = t("Please select an option.")
  }

  return errors
}

export default validateProtecciones
