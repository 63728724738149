import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"

import { AppContext } from "../../context/AppContext"
import HomeStepsContext from "../../context/HomeStepsContext"
import VariableClass from "../../helpers-shared/configuration/variable"
import { findVariable } from "../../helpers-shared/configuration/variables"
import { Button } from "../buttons/Button"

import styles from "./NormativaMessage.module.scss"

const NormativaMessage = ({ onButtonClick }) => {
  const { t, i18n } = useTranslation()

  const context = useContext(HomeStepsContext)
  const appContext = useContext(AppContext)

  const { store } = appContext
  const { values, setValue } = context

  const variableId = "CCAB_ACCESIBILIDAD"
  const variableData = findVariable(store.variables, variableId)

  const variableObj = VariableClass(variableId, variableData)
  const variableName = variableObj.getName(i18n.language)

  const noValue = "0"
  const yesValue = "EN-81-70"

  const [checked, setChecked] = useState(values[variableId] === yesValue)

  const renderEmbarqueMessage = () => {
    if (checked && ["90", "270"].includes(values.CCAB_EMBARQUE)) {
      return (
        <p className={styles.parrafo}>
          {t("normativa.accessibility_dimensions_message")}
        </p>
      )
    }

    return ""
  }

  const handleChange = () => {
    setChecked(!checked)

    const selectedValue = checked ? noValue : yesValue
    setValue({ [variableId]: selectedValue })
  }

  const isDisabled = !variableObj.isActive()

  return (
    <>
      <label
        htmlFor={variableId}
        className={[
          styles.check,
          checked ? styles.checked : " ",
          isDisabled ? styles.disabled : "",
        ].join(" ")}
      >
        <input
          className={styles.inputCheck}
          id={variableId}
          name={variableId}
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          disabled={isDisabled}
        />

        <span className={styles.checkmark}></span>
        <span className={styles.labeltext}>{variableName}</span>
      </label>

      <p className={styles.parrafo}>{t("normativa.description")}</p>
      {renderEmbarqueMessage()}

      <div className={styles.button}>
        <Button primary={true} onClick={onButtonClick}>
          {t("normativa.cta")}
        </Button>
      </div>
    </>
  )
}

export default NormativaMessage
