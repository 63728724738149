import React from "react"
import ReactDOM from "react-dom"

import App from "./App"
import "./i18n"
import reportWebVitals from "./reportWebVitals"

import "./styles/globals.scss"

const isDevelopment = process.env.NODE_ENV === "development"

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/service-worker.js")
    .then(function (registration) {
      if (isDevelopment) {
        console.info(
          "Service worker registration successful, scope is:",
          registration.scope
        )
      }
    })
    .catch(function (error) {
      if (isDevelopment) {
        console.error("Service worker registration failed, error:", error)
      }
    })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
