import { useTranslation } from "react-i18next"

import styles from "./NotAuthorizedContent.module.scss"

const NotAuthorizedContent = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.notAuthorized}>
      <p>{t("You don't have permission to access this page.")}</p>
    </div>
  )
}

export default NotAuthorizedContent
