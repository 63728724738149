import { useContext } from "react"
import { useTranslation } from "react-i18next"

import { AppContext } from "../../../context/AppContext"
import HomeStepsContext from "../../../context/HomeStepsContext"
import VariableClass from "../../../helpers-shared/configuration/variable"
import { groupOptions } from "../../../helpers-shared/configuration/variableOptions"
import AreaClass from "../../../helpers/configuration/area"
import { findArea } from "../../../helpers/configuration/areas"
import { calculateIncompatibilities } from "../../../helpers/configuration/incompatibilities"
import { getCmsAssetsUrl } from "../../../helpers/general"
import PresetDesing from "../PresetDesings/PresetDesings"
import StepContainer from "../StepContainer/StepContainer"

import styles from "./Preset.module.scss"

const assetsUrl = getCmsAssetsUrl()

const Preset = () => {
  const { i18n } = useTranslation()

  const appContext = useContext(AppContext)
  const { store } = appContext

  const context = useContext(HomeStepsContext)
  const { steps, step, nextStep, values, allAreas, setValue } = context

  if (!steps || steps.length <= 0) {
    return ""
  }

  const stepData = steps.find(s => s.number === step)
  const areaId = stepData["areaId"]

  // Data for the current area
  const areaData = findArea(allAreas, areaId)
  const areaObj = AreaClass(areaId, areaData)
  const description = areaObj.getDescription(i18n.language)
  const variable = areaObj.getFirstVariable()

  if (!variable) {
    return null
  }

  const [variableId, variableData] = variable

  const variableObj = VariableClass(variableId, variableData)

  const variablePreparedOptions = variableObj.getPreparedOptions(
    i18n.language,
    { assetsUrl }
  )

  const preparedOptions = calculateIncompatibilities(
    values,
    variableId,
    variablePreparedOptions,
    store.incompatibilities
  )

  const groups = groupOptions(preparedOptions)

  const handleSelectPreset = id => {
    setValue({ [variableId]: id })
    nextStep()
  }

  return (
    <StepContainer message={description}>
      {groups?.length > 0 && (
        <div className={styles.presetGrid}>
          {groups.map(ambience => {
            return (
              <div key={ambience.value} className={styles.blockSlider}>
                <PresetDesing data={ambience} onClick={handleSelectPreset} />
              </div>
            )
          })}
        </div>
      )}
    </StepContainer>
  )
}

export default Preset
