import { useTranslation } from "react-i18next"

import styles from "./ErrorFallback.module.scss"

const ErrorFallback = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.errorFallbackContainer}>
      <div role="alert" className={styles.errorFallback}>
        <p>{t("Something went wrong.")}</p>
        <p>{t("Please try again later.")}</p>
      </div>
    </div>
  )
}

export default ErrorFallback
