import { useTranslation } from "react-i18next"

import PropTypes from "prop-types"

import LinkButton from "../../LinkButton/LinkButton"

import styles from "./SubmissionResult.module.scss"

const SubmissionResult = ({ error }) => {
  const { t, i18n } = useTranslation()

  let message = (
    <>
      <p
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: t("Thank you for your interest."),
        }}
      />
      <p
        dangerouslySetInnerHTML={{
          __html: t("We will contact you shortly to respond to your request."),
        }}
      />
    </>
  )

  if (error) {
    message = (
      <>
        <p
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: t("An error occurred."),
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "Your request could not be submitted. Please try again later."
            ),
          }}
        />
      </>
    )
  }

  return (
    <div className={styles.content}>
      {message}
      <div className={styles.button}>
        <LinkButton to={`/${i18n.language}`} children={t("Go to home page")} />
      </div>
    </div>
  )
}

export default SubmissionResult

SubmissionResult.propTypes = {
  error: PropTypes.bool,
}

SubmissionResult.defaultProps = {
  error: false,
}
