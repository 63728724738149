import i18n from "i18next"

const validatePanelesMando = values => {
  const { t } = i18n

  const errors = {}

  if (!values.CCAB_PANEL_MANDO_CUANTOS) {
    errors.CCAB_PANEL_MANDO_CUANTOS = t("Please select an option.")
  }

  if (
    values.CCAB_PANEL_MANDO_CUANTOS === "1" &&
    values.CCAB_PANEL_MANDO_POSICION.length >
      parseInt(values.CCAB_PANEL_MANDO_CUANTOS, 10)
  ) {
    errors.CCAB_PANEL_MANDO_POSICION = t(
      "Please select the position for a single panel."
    )
  }

  if (
    values.CCAB_PANEL_MANDO_CUANTOS === "1" &&
    values.CCAB_PANEL_MANDO_POSICION.length <
      parseInt(values.CCAB_PANEL_MANDO_CUANTOS, 10)
  ) {
    errors.CCAB_PANEL_MANDO_POSICION = t(
      "Please select the position for a panel."
    )
  }

  if (
    values.CCAB_PANEL_MANDO_CUANTOS === "2" &&
    values.CCAB_PANEL_MANDO_POSICION.length >
      parseInt(values.CCAB_PANEL_MANDO_CUANTOS, 10)
  ) {
    errors.CCAB_PANEL_MANDO_POSICION = t(
      "Please select the position for two panels maximum."
    )
  }

  if (
    values.CCAB_PANEL_MANDO_CUANTOS === "2" &&
    values.CCAB_PANEL_MANDO_POSICION.length <
      parseInt(values.CCAB_PANEL_MANDO_CUANTOS, 10)
  ) {
    errors.CCAB_PANEL_MANDO_POSICION = t(
      "Please select the position for two panels."
    )
  }

  if (!values.CCAB_PANEL_MANDO_MATERIAL) {
    errors.CCAB_PANEL_MANDO_MATERIAL = t("Please select an option.")
  }

  if (!values.CCAB_PANEL_MANDO_CABINA_SENAL) {
    errors.CCAB_PANEL_MANDO_CABINA_SENAL = t("Please select an option.")
  }

  if (!values.CCAB_PANEL_MANDO_SENAL_CABINA_PULSADOR) {
    errors.CCAB_PANEL_MANDO_SENAL_CABINA_PULSADOR = t(
      "Please select an option."
    )
  }

  if (
    values.CCAB_ACCESIBILIDAD === "EN-81-70" &&
    values.CCAB_EMBARQUE === "90" &&
    values.CCAB_PANEL_MANDO_CUANTOS !== "2"
  ) {
    errors.CCAB_PANEL_MANDO_CUANTOS = t(
      "To comply with the EN 81-70 standard, there must be two control panels in the cabin."
    )
  }

  if (
    values.CCAB_ACCESIBILIDAD === "EN-81-70" &&
    values.CCAB_EMBARQUE === "270" &&
    values.CCAB_PANEL_MANDO_CUANTOS !== "2"
  ) {
    errors.CCAB_PANEL_MANDO_CUANTOS = t(
      "To comply with the EN 81-70 standard, there must be two control panels in the cabin."
    )
  }

  return errors
}

export default validatePanelesMando
