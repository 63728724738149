import React from "react"

import PropTypes from "prop-types"

import ListTitle from "../ListTitle/ListTitle"
import Image from "../image/Image"

import styles from "./CardBoarding.module.scss"

const CardBoarding = ({ data, uppercase, hoverd, disabled, selected }) => {
  const { title, images } = data
  const getClass = ({ hoverd, disabled }) => {
    if (disabled) {
      return styles.disabled
    }
    if (selected) {
      return styles.selected
    }
    if (!hoverd) {
      return " "
    } else {
      return styles.nohover
    }
  }
  return (
    <div
      className={[styles.card, getClass({ hoverd, disabled, selected })].join(
        " "
      )}
    >
      <div className={styles.contentImg}>
        <Image images={[images]} alt="string" />
      </div>
      <span className={styles.space}>|</span>
      <div className={styles.contentTitle}>
        <ListTitle inboarding={true} uppercase={uppercase}>
          {title}
        </ListTitle>
      </div>
    </div>
  )
}

export default CardBoarding
CardBoarding.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  }),
  uppercase: PropTypes.bool,
  hoverd: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
}

CardBoarding.defaultProps = {
  data: null,
  uppercase: false,
  hoverd: false,
  disabled: false,
  selected: false,
}
