import React from "react"
import { useTranslation } from "react-i18next"
import { useMediaQuery } from "react-responsive"
import { Link } from "react-router-dom"

import PropTypes from "prop-types"

import LanguageSwitcher from "../../components/LanguageSwitcher/LanguageSwitcher"
import useCustom from "../../hooks/use-Custom"
import useCCR from "../../hooks/use-CCR"
import useScroll from "../../hooks/use-Scroll"
import useDecodeCustomParameter from "../../hooks/use-decode-custom-parameter"
import useReloadWarning from "../../hooks/use-reload-warning"

import styles from "./Header.module.scss"

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ query: "(max-width:992px)" })
  return isMobile ? children : null
}
const PC = ({ children }) => {
  const isPc = useMediaQuery({ query: "(min-width: 993px)" })
  return isPc ? children : null
}

const Header = ({ data, inConfiguration, children }) => {
  const { t, i18n } = useTranslation()
  const { isCustom } = useCustom()
  const { isCCR } = useCCR()
  const { values } = useDecodeCustomParameter()

  const { handleReloadWarning } = useReloadWarning(inConfiguration)
  const y = useScroll()
  const windowWidth = window.innerWidth

  const isViewer = isCCR || isCustom
  const companyName = values?.CCAB_EMPRESA_NOMBRE || "Orona"
  const logoFileName = values?.CCAB_EMPRESA_LOGO || "orona.svg"

  return (
    <header
      className={[
        styles.header,
        inConfiguration === true ? styles.inConfiguration : "",
        y > 2 && windowWidth < 993 ? styles.isScrolling : " ",
      ].join(" ")}
    >
      <Mobile>
        {!isViewer && (
          <div className={styles.contentMobildioma}>
            <div className={styles.idiomasMobil}>
              <LanguageSwitcher />
            </div>
          </div>
        )}
      </Mobile>{" "}
      <div className={styles.wrapper}>
        <div className={styles.contentHeader}>
          <div className={styles.logo}>
            {isViewer && values ? (
              <div className={styles.placeHolderLogo}>
                <img src={`/data/images/logos/${logoFileName}`} alt={companyName} />
                <Mobile>
                  {!inConfiguration && (
                    <h1 className={styles.h1}>{data.titulo}</h1>
                  )}
                </Mobile>
              </div>
            ) : (
              <Link
                to={`/${i18n.language}`}
                onClick={event => handleReloadWarning(event)}
              >
                <Mobile>
                  <div className={styles.placeHolderLogo}>
                    {!isViewer && (
                      <div className={styles.titleNoConfiguration}>
                        <img src={`/data/images/logos/${logoFileName}`} alt={companyName} />
                        {!inConfiguration && (
                          <div className={styles.titular}>
                            <h1 className={styles.h1}>{data.titulo}</h1>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Mobile>
                <PC>
                  <div className={styles.placeHolderLogo}>
                    {!isViewer ? <img src={`/data/images/logos/${logoFileName}`} alt={companyName} /> : null}
                  </div>
                </PC>
              </Link>
            )}
          </div>
          <PC>
            <div className={styles.titular}>
              {isCCR ? (
                <h1 className={styles.h1}>{t("Orona Viewer")}</h1>
              ) : isCustom || !isViewer ? (
                <h1 className={styles.h1}>{data.titulo}</h1>
              ) : null}
            </div>
            {!isViewer && (
              <div className={styles.contentLang}>
                <LanguageSwitcher />
              </div>
            )}
          </PC>
        </div>

        {children}
      </div>
      {isCCR && values?.CCAB_OFERTA && (
        <Mobile>
          <div className={styles.contentText}>
            <p className={styles.textOffer}>
              {t("Offer Nº")}: {values.CCAB_OFERTA}
            </p>
          </div>
        </Mobile>
      )}
    </header>
  )
}

export default Header

Header.propTypes = {
  inConfiguration: PropTypes.bool,
  data: PropTypes.shape({
    title: PropTypes.string,
  }),
  children: PropTypes.node,
}

Header.defaultProps = {
  inConfiguration: false,
  children: null,
}
