import React from "react"

import PropTypes from "prop-types"

import styles from "./Subtitle.module.scss"

const SubTitle = ({ children }) => (
  <h4 className={styles.subTitle}>{children}</h4>
)

export default SubTitle

SubTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

SubTitle.defaultProps = {
  children: null,
}
