import { useContext, useEffect } from "react"

import { CasUserContext } from "./context/CasUserContext"
import useCasTokens from "./hooks/use-cas-tokens"

const RequireAuth = ({ children }) => {
  const casUserContext = useContext(CasUserContext)

  const cas = useCasTokens()

  useEffect(() => {
    if (!cas) {
      return
    }

    if (!casUserContext.user) {
      cas.attemptCasLogin()
      return false
    }
  }, [casUserContext.user, cas])

  if (!casUserContext.user) {
    return <p>Loading...</p>
  }

  return children
}

export default RequireAuth
