import useDecodeCustomParameter from "../../hooks/use-decode-custom-parameter"
import Configure from "./Configure"

const ConfigureCustom = () => {
  const { loaded, values, valid } = useDecodeCustomParameter()

  // Don't init the values until the protobuf has been decoded
  if (!loaded) {
    return ""
  }

  return <Configure initialValues={values} valid={valid} />
}

export default ConfigureCustom
