import { initReactI18next } from "react-i18next"

import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"

import {
  getCmsAssetsUrl,
  getDefaultLanguage,
  getEnabledLanguages,
} from "./helpers/general"
import locales from "./locales"

const isProduction = process.env.NODE_ENV === "production"

const enabledLanguages = getEnabledLanguages(locales)
const defaultLanguage = getDefaultLanguage(locales)

const assetsUrl = getCmsAssetsUrl()

// Do cache busting of locale files every time the site gets loaded
const cacheBustVersion = `?v=${Date.now()}`

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !isProduction,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: [
        "path",
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "subdomain",
      ],
    },
    supportedLngs: enabledLanguages ? Object.keys(enabledLanguages) : null,
    backend: {
      loadPath: `${assetsUrl}/locales/{{lng}}/{{ns}}.json${cacheBustVersion}`,
    },
  })

export default i18n
