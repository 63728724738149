import { useContext } from "react"
import { useTranslation } from "react-i18next"

import PropTypes from "prop-types"

import { AppContext } from "../../../context/AppContext"
import VariableClass from "../../../helpers-shared/configuration/variable"
import { findVariable } from "../../../helpers-shared/configuration/variables"
import AreaClass from "../../../helpers/configuration/area"
import { findAreaPath } from "../../../helpers/configuration/areas"

import styles from "./ConfigurationErrors.module.scss"

const ConfigurationErrors = ({ errors }) => {
  const { t, i18n } = useTranslation()
  const appContext = useContext(AppContext)
  const { store } = appContext

  const formatErrors = errors => {
    const errorList = { ...errors }
    delete errorList.personal_data

    return Object.entries(errorList).map(error => {
      const [variableId, errorMessage] = error

      const variableData = findVariable(store.variables, variableId)
      const variableObj = VariableClass(variableId, variableData)
      const variableName = variableObj.getName(i18n.language)

      const areaId = variableData.CATEGORIA
      const areaPath = findAreaPath(store.categories, areaId)

      let pathText = ""

      if (areaPath) {
        pathText = areaPath
          .map(area => {
            const entries = Object.entries(area)
            const [areaId, areaData] = entries[0]

            const areaObj = AreaClass(areaId, areaData)
            const areaName = areaObj.getName(i18n.language)

            return areaName
          })
          .join(" > ")
      }

      return {
        path: pathText,
        variableName: variableName,
        message: errorMessage,
      }
    })
  }

  const renderErrors = (errorList = []) => {
    if (errorList.length <= 0) {
      return ""
    }

    const formatted = errorList.map((error, index) => (
      <li key={`error-${index}-${error.variableName}`}>
        <span>
          {error.path} > {error.variableName}
        </span>
        : {error.message}
      </li>
    ))

    return <ul>{formatted}</ul>
  }

  const errorList = formatErrors(errors)

  if (errorList.length <= 0) {
    return ""
  }

  return (
    <div className={styles.errors}>
      <p>{t("Please review the following errors:")}</p>
      {renderErrors(errorList)}
    </div>
  )
}

export default ConfigurationErrors

ConfigurationErrors.propTypes = {
  errors: PropTypes.object,
}

ConfigurationErrors.defaultProps = {
  errors: null,
}
