import { getCacheBustVersionParam } from "../../helpers/general"

function VariableOptionClass(id, variableData) {
  const obj = Object.create(variableData)
  obj.ID = id

  function getId() {
    return obj.ID
  }

  function getName(locale) {
    if (!obj.ID) {
      return null
    }

    const name = obj.TEXTO?.[locale] ?? obj.ID

    return name
  }

  function getShortName(locale) {
    if (!obj.ID) {
      return null
    }

    const shortName = obj.TEXTO_CORTO?.[locale] ?? getName(locale) ?? obj.ID

    return shortName
  }

  function getDescription(locale) {
    if (!obj.ID) {
      return null
    }

    const description = obj.DESCRIPCION?.[locale] ?? obj.ID

    return description
  }

  const isGroupTitle = () => {
    if (!obj.ID) {
      return false
    }

    if (typeof obj.ID !== 'string') {
      return false
    }

    return obj.ID.startsWith("AGRUPACION_")
  }

  function getImage(assetsUrl = "") {
    if (!obj.IMAGEN) {
      return ""
    }

    const cacheBustVersion = getCacheBustVersionParam()

    return `${assetsUrl}${obj.IMAGEN}` + cacheBustVersion
  }

  function getPrepared(locale, config = {}) {
    const { assetsUrl = "" } = config

    const preparedOption = {
      text: getName(locale),
      shortText: getShortName(locale),
      description: getDescription(locale),
      value: obj.ID,
      isGroupTitle: isGroupTitle(),
      image: getImage(assetsUrl),
      active: isActive(),
      order: getOrder(),
      hidden: !isVisible(),
      note: getNote(locale),
    }

    return preparedOption
  }

  function isActive() {
    return obj.ACTIVO ?? true
  }

  function isVisible() {
    return obj.VISIBLE ?? true
  }

  function getOrder() {
    if (!obj.ORDEN) {
      return 0
    }

    return obj.ORDEN
  }

  function getNote(locale) {
    return obj.NOTA?.[locale] ?? ""
  }

  return {
    getId,
    getName,
    isGroupTitle,
    getPrepared,
    isActive,
  }
}

export default VariableOptionClass
