import VariableClass from "./variable.js"

const findVariable = (variables, variableId) => {
  if (!variableId || !variables || variables.length <= 0) {
    return null
  }

  const found = variables[variableId] ?? null

  if (!found) {
    return null
  }

  return found
}

const getVariableOptionImages = (variables, config = {}) => {
  const { locale = "en", assetsUrl = "" } = config

  if (!variables || Object.entries(variables).length <= 0) {
    return null
  }

  const images = Object.entries(variables)
    .map(variable => {
      const [variableId, variableData] = variable

      const variableObj = VariableClass(variableId, variableData)
      const options = variableObj.getPreparedOptions(locale, { assetsUrl })

      if (!options || options.length <= 0) {
        return null
      }

      const optionImages = options.map(o => o.image)

      return optionImages
    })
    .flat()
    .filter(i => i)

  return images
}

export { findVariable, getVariableOptionImages }
