import { useParams } from "react-router-dom"

const Summary = () => {
  const params = useParams()

  return (
    <>
      <h2>Summary</h2>
      <p>Cabin: {params.CCAB_MODELO ?? "Not specified"}</p>
      <p>Boarding: {params.CCAB_EMBARQUE ?? "Not specified"}</p>
    </>
  )
}

export default Summary
