import VariableOptionClass from "./variableOption.js"

function VariableClass(id, variableData) {
  const obj = Object.create(variableData)
  obj.ID = id

  function getId() {
    return obj.ID
  }

  function getName(locale) {
    if (!obj.NOMBRE || !obj.ID) {
      return null
    }

    const nombre = obj.NOMBRE?.[locale] ?? obj.ID

    return nombre
  }

  function getOptions() {
    return obj.VALORES ?? null
  }

  function getPreparedOptions(locale, config = {}) {
    const { sorted = false, assetsUrl = "" } = config

    if (!obj.VALORES) {
      return null
    }

    const options = Object.entries(obj.VALORES).map(option => {
      const [optionId, optionData] = option

      const variableOptionObj = VariableOptionClass(optionId, optionData)
      return variableOptionObj.getPrepared(locale, { assetsUrl })
    })

    if (sorted) {
      // Ascending order
      return options.sort((a, b) => (a.order > b.order ? 1 : -1))
    }

    return options
  }

  function displayInTab() {
    return obj.EN_TAB
  }

  function isMultiselect() {
    return obj.MULTISELECCION
  }

  function displayLabel() {
    return !obj.SIN_LABEL
  }

  function getVisualizacion() {
    return obj.VISUALIZACION
  }

  function isVisible() {
    return obj.VISIBLE ?? true
  }

  function isActive() {
    return isVisible()
  }

  return {
    getId,
    getName,
    getOptions,
    getPreparedOptions,
    displayInTab,
    isMultiselect,
    displayLabel,
    getVisualizacion,
    isActive,
  }
}

export default VariableClass
