// https://github.com/jaredpalmer/formik/issues/1840#issuecomment-610460209
// getIn and setIn are helpers exported from Formik which get and set values in complex objects,
// returns a new object if setIn causes a change
// example field names for brevity
import { getIn, setIn } from "formik"

import {
  canHaveEspejoModelo,
  getParedAcabado,
  hasAnyGlassAmbienceWall,
  hasAnyGlazedWall,
  hasDoorOnSide,
  isGlassAmbienceWall,
  isGlazedWall,
} from "../../../helpers-shared/configuration/utils"
import { getMainWallId, removeFromSide, removeDuplicates } from "../general"

const updateAllDependentValues = (name = "", value, formik) => {
  const { values, initialValues } = formik

  // update values object with the newly changed value
  let updatedValues = setIn(values, name, value)

  if (getIn(values, name) !== getIn(updatedValues, name)) {
    // value changed, recalculate other values if needed

    const resetValues = recalculateValues(updatedValues, initialValues)

    updatedValues = { ...updatedValues, ...resetValues }
  }

  return updatedValues
}

const getValueForCheckbox = (event, values) => {
  const { name, value, checked } = event.target

  const currentValue = getIn(values, name)
  const valueProp = value

  // If the current value was a boolean, return a boolean
  if (typeof currentValue === "boolean") {
    return Boolean(checked)
  } // If the currentValue was not a boolean we want to return an array

  var currentArrayOfValues = []
  var isValueInArray = false
  var index = -1

  if (!Array.isArray(currentValue)) {
    // eslint-disable-next-line eqeqeq
    if (!valueProp || valueProp == "true" || valueProp == "false") {
      return Boolean(checked)
    }
  } else {
    // If the current value is already an array, use it
    currentArrayOfValues = currentValue
    index = currentValue.indexOf(valueProp)
    isValueInArray = index >= 0
  } // If the checkbox was checked and the value is not already present in the aray we want to add the new value to the array of values

  if (checked && valueProp && !isValueInArray) {
    return currentArrayOfValues.concat(valueProp)
  } // If the checkbox was unchecked and the value is not in the array, simply return the already existing array of values

  if (!isValueInArray) {
    return currentArrayOfValues
  } // If the checkbox was unchecked and the value is in the array, remove the value and return the array

  return currentArrayOfValues
    .slice(0, index)
    .concat(currentArrayOfValues.slice(index + 1))
}

const resetWallDependentValues = (values, initialValues) => {
  let newValues = { ...values }

  const sides = ["IZQUIERDA", "FRONTAL", "DERECHA"]

  sides.forEach(side => {
    const acabadoPared = getParedAcabado(side, newValues)

    // Pared CRISTAL + espejo

    if (
      isGlazedWall(acabadoPared) &&
      newValues.CCAB_ESPEJO_PARED.includes(side)
    ) {
      newValues.CCAB_ESPEJO_PARED = removeFromSide(
        side,
        newValues.CCAB_ESPEJO_PARED
      )
    }

    // Pared CRISTAL -> no se puede quitar el pasamanos

    if (isGlazedWall(acabadoPared)) {
      newValues.CCAB_PASAMANO_POSICION = removeDuplicates([
        ...newValues.CCAB_PASAMANO_POSICION,
        side,
      ])

      if (isEmpty(newValues.CCAB_PASAMANO_MODELO)) {
        newValues.CCAB_PASAMANO_MODELO = initialValues.CCAB_PASAMANO_MODELO
      }
    }

    // Pared CRISTAL + pasamanos incompatibles

    if (
      isGlazedWall(acabadoPared) &&
      newValues.CCAB_PASAMANO_POSICION.includes(side) &&
      ["PAMA_HDR13", "PAMA_HDR14"].includes(newValues.CCAB_PASAMANO_MODELO)
    ) {
      // Ponemos una que sabemos que no tiene incompatibilidades
      newValues.CCAB_PASAMANO_MODELO = "PAMA_HDR01"
    }

    // Pared PG (Glass ambience) + espejo incompatible (completo estrecho, completo ancho)

    if (
      isGlassAmbienceWall(acabadoPared) &&
      newValues.CCAB_ESPEJO_PARED.includes(side) &&
      ["MT_COMPLETO_ESTRECHO", "MW_COMPLETO_ANCHO"].includes(
        newValues.CCAB_ESPEJO_MODELO
      )
    ) {
      newValues.CCAB_ESPEJO_PARED = removeFromSide(
        side,
        newValues.CCAB_ESPEJO_PARED
      )
    }
  })

  if (isEmpty(newValues.CCAB_ESPEJO_PARED)) {
    newValues.CCAB_ESPEJO_ACABADO = ""
    newValues.CCAB_ESPEJO_MODELO = ""
  }

  if (isEmpty(newValues.CCAB_PASAMANO_POSICION)) {
    newValues.CCAB_PASAMANO_MODELO = ""
  }

  // Si alguna pared es cristal o glass ambience -> no se puede accesorios (bandas de protección ni multiples pasamanos)

  if (hasAnyGlassAmbienceWall(newValues) || hasAnyGlazedWall(newValues)) {
    newValues.CCAB_PROTECCIONES_TIPO = "NO_BAND"
  }

  return newValues
}

const recalculateValues = (values, initialValues) => {
  let newValues = { ...values }

  // TODO: Apply rules here

  // All walls same
  if (newValues.all_walls_same) {
    const mainWallId = getMainWallId(newValues)
    const allWallsAcabado = newValues[mainWallId]

    if (!hasDoorOnSide("IZQUIERDA", newValues)) {
      newValues.CCAB_PARED_IZDA_ACABADO = allWallsAcabado
    } else {
      newValues.CCAB_PARED_IZDA_ACABADO = ""
    }

    if (!hasDoorOnSide("DERECHA", newValues)) {
      newValues.CCAB_PARED_DCHA_ACABADO = allWallsAcabado
    } else {
      newValues.CCAB_PARED_DCHA_ACABADO = ""
    }

    if (!hasDoorOnSide("FRONTAL", newValues)) {
      newValues.CCAB_PARED_FRONT_ACABADO = allWallsAcabado
    } else {
      newValues.CCAB_PARED_FRONT_ACABADO = ""
    }
  }

  newValues = {
    ...newValues,
    ...resetWallDependentValues(newValues, initialValues),
  }

  // Espejos
  if (isEmpty(newValues.CCAB_ESPEJO_PARED)) {
    newValues.CCAB_ESPEJO_ACABADO = ""
    newValues.CCAB_ESPEJO_MODELO = ""
  }

  if (
    isNotEmpty(newValues.CCAB_ESPEJO_PARED) &&
    isEmpty(newValues.CCAB_ESPEJO_ACABADO)
  ) {
    newValues.CCAB_ESPEJO_ACABADO = initialValues.CCAB_ESPEJO_ACABADO
  }

  if (
    isNotEmpty(newValues.CCAB_ESPEJO_PARED) &&
    isEmpty(newValues.CCAB_ESPEJO_MODELO)
  ) {
    if (canHaveEspejoModelo(initialValues.CCAB_ESPEJO_MODELO, newValues)) {
      newValues.CCAB_ESPEJO_MODELO = initialValues.CCAB_ESPEJO_MODELO
    } else if (canHaveEspejoModelo("MH_MEDIO_ESPEJO", newValues)) {
      newValues.CCAB_ESPEJO_MODELO = "MH_MEDIO_ESPEJO"
    } else {
      newValues.CCAB_ESPEJO_MODELO = ""
    }
  }

 // Paneles de mando
  if (isEmpty(newValues.CCAB_PANEL_MANDO_MATERIAL)) {
    newValues.CCAB_PANEL_MANDO_MATERIAL = initialValues.CCAB_PANEL_MANDO_MATERIAL
  }

  if (isEmpty(newValues.CCAB_PANEL_MANDO_CABINA_SENAL)) {
    newValues.CCAB_PANEL_MANDO_CABINA_SENAL = initialValues.CCAB_PANEL_MANDO_CABINA_SENAL
  }

  if (isEmpty(newValues.CCAB_PANEL_MANDO_SENAL_CABINA_PULSADOR)) {
    newValues.CCAB_PANEL_MANDO_SENAL_CABINA_PULSADOR = initialValues.CCAB_PANEL_MANDO_SENAL_CABINA_PULSADOR
  }

  if (isEmpty(newValues.CCAB_PANEL_MANDO_CONTRASTE)) {
    newValues.CCAB_PANEL_MANDO_CONTRASTE = initialValues.CCAB_PANEL_MANDO_CONTRASTE
  }

  if (
    ["NEGRO", "INOX_NEGRO"].includes(newValues.CCAB_BOTONERA_MARCO) &&
    ["INOX_BLACK", "BLACK_INOX_DUAL"].includes(newValues.CCAB_BOTONERA_PULSADOR)
  ) {
    newValues.CCAB_BOTONERA_CONTRASTE = true
  }

  if (isEmpty(newValues.CCAB_BOTONERA_CONTRASTE)) {
    newValues.CCAB_BOTONERA_CONTRASTE = initialValues.CCAB_BOTONERA_CONTRASTE
  }

  // Pasamanos
  if (isEmpty(newValues.CCAB_PASAMANO_POSICION)) {
    newValues.CCAB_PASAMANO_MODELO = ""
  }

  if (
    isNotEmpty(newValues.CCAB_PASAMANO_POSICION) &&
    isEmpty(newValues.CCAB_PASAMANO_MODELO)
  ) {
    newValues.CCAB_PASAMANO_MODELO = initialValues.CCAB_PASAMANO_MODELO
  }

  // Accesorios (protecciones)
  if (newValues.CCAB_PROTECCIONES_TIPO === "NO_BAND") {
    newValues.CCAB_PROTECCIONES_MATERIAL = ""
    newValues.CCAB_PROTECCIONES_MODELO = ""
  }

  if (newValues.CCAB_PROTECCIONES_TIPO === "BANDAS") {
    newValues.CCAB_PROTECCIONES_MODELO = ""
  }

  if (newValues.CCAB_PROTECCIONES_TIPO === "MULTIPLES_PASAMANOS") {
    newValues.CCAB_PROTECCIONES_MATERIAL = ""
    newValues.CCAB_PASAMANO_POSICION = ""
  }

  if (
    newValues.CCAB_PROTECCIONES_TIPO === "BANDAS" &&
    isEmpty(newValues.CCAB_PROTECCIONES_MATERIAL)
  ) {
    newValues.CCAB_PROTECCIONES_MATERIAL = initialValues.CCAB_PROTECCIONES_MATERIAL
  }

  if (
    newValues.CCAB_PROTECCIONES_TIPO === "MULTIPLES_PASAMANOS" &&
    isEmpty(newValues.CCAB_PROTECCIONES_MODELO)
  ) {
    newValues.CCAB_PROTECCIONES_MODELO = initialValues.CCAB_PROTECCIONES_MODELO
  }

  // Paneles de mando (combinadas)

  if (
    newValues.CCAB_EMBARQUE === "0" &&
    newValues.CCAB_PANEL_MANDO_CUANTOS === "1"
  ) {
    newValues.CCAB_PANEL_MANDO_POSICION = newValues.CCAB_PANEL_MANDO_POSICION.filter(side => side !== "FRONTAL")
  }

  if (
    newValues.CCAB_EMBARQUE === "0" &&
    newValues.CCAB_PANEL_MANDO_CUANTOS === "1" &&
    newValues.CCAB_PUERTA_MODELO === "T2H"
  ) {
    newValues.CCAB_PANEL_MANDO_POSICION = ["DERECHA"]

    // Si hay un espejo en el lado, lo quitamos para poner el panel
    newValues.CCAB_ESPEJO_PARED = removeFromSide(
      "DERECHA",
      newValues.CCAB_ESPEJO_PARED
    )
  }

  if (
    newValues.CCAB_EMBARQUE === "90" &&
    newValues.CCAB_PANEL_MANDO_CUANTOS === "1" &&
    newValues.CCAB_PUERTA_MODELO === "T2H"
  ) {
    newValues.CCAB_PANEL_MANDO_POSICION = ["IZQUIERDA"]

    // Si hay un espejo en el lado, lo quitamos para poner el panel
    newValues.CCAB_ESPEJO_PARED = removeFromSide(
      "IZQUIERDA",
      newValues.CCAB_ESPEJO_PARED
    )
  }

  if (
    newValues.CCAB_EMBARQUE === "180" &&
    newValues.CCAB_PANEL_MANDO_CUANTOS === "1" &&
    newValues.CCAB_PUERTA_MODELO === "T2H"
  ) {
    newValues.CCAB_PANEL_MANDO_POSICION = ["DERECHA"]

    // Si hay un espejo en el lado, lo quitamos para poner el panel
    newValues.CCAB_ESPEJO_PARED = removeFromSide(
      "DERECHA",
      newValues.CCAB_ESPEJO_PARED
    )
  }

  if (
    newValues.CCAB_EMBARQUE === "270" &&
    newValues.CCAB_PANEL_MANDO_CUANTOS === "1" &&
    newValues.CCAB_PUERTA_MODELO === "T2H"
  ) {
    newValues.CCAB_PANEL_MANDO_POSICION = ["DERECHA"]

    // Si hay un espejo en el lado, lo quitamos para poner el panel
    newValues.CCAB_ESPEJO_PARED = removeFromSide(
      "DERECHA",
      newValues.CCAB_ESPEJO_PARED
    )
  }

  if (
    newValues.CCAB_EMBARQUE === "0" &&
    newValues.CCAB_PANEL_MANDO_CUANTOS === "2" &&
    newValues.CCAB_PUERTA_MODELO === "T2H"
  ) {
    newValues.CCAB_PANEL_MANDO_POSICION = selectTwoWallsOneCompulsory(
      newValues.CCAB_PANEL_MANDO_POSICION,
      "DERECHA"
    )

    // Si hay un espejo en el lado, lo quitamos para poner el panel
    newValues.CCAB_ESPEJO_PARED = removeFromSide(
      "DERECHA",
      newValues.CCAB_ESPEJO_PARED
    )
  }

  if (
    newValues.CCAB_ACCESIBILIDAD === "EN-81-70" &&
    newValues.CCAB_EMBARQUE === "0" &&
    newValues.CCAB_PANEL_MANDO_CUANTOS === "1"
  ) {
    newValues.CCAB_PANEL_MANDO_POSICION = ["DERECHA"]

    // Si hay un espejo en el lado, lo quitamos para poner el panel
    newValues.CCAB_ESPEJO_PARED = removeFromSide(
      "DERECHA",
      newValues.CCAB_ESPEJO_PARED
    )
  }

  if (
    newValues.CCAB_ACCESIBILIDAD === "EN-81-70" &&
    newValues.CCAB_EMBARQUE === "0" &&
    newValues.CCAB_PANEL_MANDO_CUANTOS === "2"
  ) {
    newValues.CCAB_PANEL_MANDO_POSICION = selectTwoWallsOneCompulsory(
      newValues.CCAB_PANEL_MANDO_POSICION,
      "DERECHA"
    )

    // Si hay un espejo en el lado, lo quitamos para poner el panel
    newValues.CCAB_ESPEJO_PARED = removeFromSide(
      "DERECHA",
      newValues.CCAB_ESPEJO_PARED
    )
  }

  if (
    newValues.CCAB_ACCESIBILIDAD === "EN-81-70" &&
    newValues.CCAB_EMBARQUE === "180" &&
    newValues.CCAB_PANEL_MANDO_CUANTOS === "1"
  ) {
    newValues.CCAB_PANEL_MANDO_POSICION = ["DERECHA"]

    // Si hay un espejo en el lado, lo quitamos para poner el panel
    newValues.CCAB_ESPEJO_PARED = removeFromSide(
      "DERECHA",
      newValues.CCAB_ESPEJO_PARED
    )
  }

  if (
    newValues.CCAB_ACCESIBILIDAD === "EN-81-70" &&
    newValues.CCAB_EMBARQUE === "180" &&
    newValues.CCAB_PANEL_MANDO_CUANTOS === "2"
  ) {
    newValues.CCAB_PANEL_MANDO_POSICION = selectTwoWallsOneCompulsory(
      newValues.CCAB_PANEL_MANDO_POSICION,
      "DERECHA"
    )

    // Si hay un espejo en el lado, lo quitamos para poner el panel
    newValues.CCAB_ESPEJO_PARED = removeFromSide(
      "DERECHA",
      newValues.CCAB_ESPEJO_PARED
    )
  }

  if (
    newValues.CCAB_ACCESIBILIDAD === "EN-81-70" &&
    newValues.CCAB_EMBARQUE === "0"
  ) {
    // check EN 81-70 activo y embarque simple, espejo en la pared de fondo obligatorio
    newValues.CCAB_ESPEJO_PARED = removeDuplicates([
      ...newValues.CCAB_ESPEJO_PARED,
      "FRONTAL",
    ])
  }

  // Espejos: comprobar si hace falta resetear acabado y modelo aquí también
  if (isEmpty(newValues.CCAB_ESPEJO_PARED)) {
    newValues.CCAB_ESPEJO_ACABADO = ""
    newValues.CCAB_ESPEJO_MODELO = ""
  }

  if (
    newValues.CCAB_EMBARQUE === "90" &&
    newValues.CCAB_PANEL_MANDO_CUANTOS === "2"
  ) {
    newValues.CCAB_PANEL_MANDO_POSICION = ["FRONTAL", "IZQUIERDA"]
  }

  if (
    newValues.CCAB_EMBARQUE === "180" &&
    newValues.CCAB_PANEL_MANDO_CUANTOS === "2"
  ) {
    newValues.CCAB_PANEL_MANDO_POSICION = ["DERECHA", "IZQUIERDA"]
  }

  if (
    newValues.CCAB_EMBARQUE === "270" &&
    newValues.CCAB_PANEL_MANDO_CUANTOS === "2"
  ) {
    newValues.CCAB_PANEL_MANDO_POSICION = ["DERECHA", "FRONTAL"]
  }

  if (
    newValues.CCAB_ACCESIBILIDAD === "EN-81-70" &&
    newValues.CCAB_EMBARQUE === "90"
  ) {
    newValues.CCAB_PANEL_MANDO_CUANTOS = "2"
  }

  if (
    newValues.CCAB_ACCESIBILIDAD === "EN-81-70" &&
    newValues.CCAB_EMBARQUE === "270"
  ) {
    newValues.CCAB_PANEL_MANDO_CUANTOS = "2"
  }

  if (
    newValues.CCAB_ACCESIBILIDAD === "EN-81-70"
  ) {
    newValues.CCAB_PANEL_MANDO_CONTRASTE = true
    newValues.CCAB_BOTONERA_CONTRASTE = true
  }

  if (newValues.CCAB_ACCESIBILIDAD === "EN-81-70") {
    newValues.CCAB_PASAMANO_POSICION = removeDuplicates([
      ...newValues.CCAB_PASAMANO_POSICION,
      ...newValues.CCAB_PANEL_MANDO_POSICION,
    ])
  }

  if (!newValues.CCAB_SENAL_PISO) {
    newValues.CCAB_SENAL_PISO_TIPO = ""
    newValues.CCAB_SENAL_PISO_UBICACION = ""
  }

  if (newValues.CCAB_SENAL_PISO && isEmpty(newValues.CCAB_SENAL_PISO_TIPO)) {
    newValues.CCAB_SENAL_PISO_TIPO = initialValues.CCAB_SENAL_PISO_TIPO
  }

  if (
    newValues.CCAB_SENAL_PISO &&
    isEmpty(newValues.CCAB_SENAL_PISO_UBICACION)
  ) {
    newValues.CCAB_SENAL_PISO_UBICACION = initialValues.CCAB_SENAL_PISO_UBICACION
  }

  if (
    ["MATRIZ_P_PISO_NEXT", "TFT_PISO_NEXT"].includes(newValues.CCAB_SENAL_PISO_TIPO)
  ) {
    newValues.CCAB_SENAL_PISO_MARCO_NEGRO = true;
  }

  if (
    !newValues.CCAB_SENAL_PISO_TIPO
 ) {
   newValues.CCAB_SENAL_PISO_MARCO_NEGRO = false;
 }

  return newValues
}

const isEmpty = value => {
  return Array.isArray(value) ? value.length <= 0 : value === ""
}

const isNotEmpty = value => {
  return Array.isArray(value) ? value.length > 0 : value !== ""
}

/**
 * Get two walls:
 * - The compulsory one
 * - Any other selected wall
 *
 * @param value
 * @param compulsoryWall
 * @returns {*[]}
 */
const selectTwoWallsOneCompulsory = (value, compulsoryWall) => {
  let walls = [compulsoryWall]

  if (value.length <= 0) {
    return walls
  }

  const one = value.find(wall => wall !== compulsoryWall)

  if (!one) {
    return walls
  }

  walls = removeDuplicates([...walls, one])

  return walls
}

export { updateAllDependentValues, getValueForCheckbox, recalculateValues }
