import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"

import HomeStepsContext from "../../../context/HomeStepsContext"
import VariableClass from "../../../helpers-shared/configuration/variable"
import AreaClass from "../../../helpers/configuration/area"
import { findArea } from "../../../helpers/configuration/areas"
import { getCmsAssetsUrl } from "../../../helpers/general"
import Card from "../../card/Card"
import StepContainer from "../StepContainer/StepContainer"

import styles from "./Model.module.scss"

const assetsUrl = getCmsAssetsUrl()

const Model = () => {
  const { i18n } = useTranslation()
  const [hovered, setHovered] = useState(null)

  const context = useContext(HomeStepsContext)

  const { steps, step, nextStep, values, allAreas, setValue } = context

  if (!steps || steps.length <= 0) {
    return ""
  }

  const stepData = steps.find(s => s.number === step)
  const areaId = stepData["areaId"]

  // Data for the current area
  const areaData = findArea(allAreas, areaId)
  const areaObj = AreaClass(areaId, areaData)
  const description = areaObj.getDescription(i18n.language)
  const variable = areaObj.getFirstVariable()

  if (!variable) {
    return null
  }

  const [variableId, variableData] = variable

  const variableObj = VariableClass(variableId, variableData)

  const variablePreparedOptions = variableObj.getPreparedOptions(
    i18n.language,
    { assetsUrl }
  )

  const handleSelect = id => {
    setValue({ [variableId]: id })
    nextStep()
  }

  const getHoveredClass = id => {
    if (!hovered) {
      return false
    }
    if (hovered && hovered === id) {
      return false
    }
    return true
  }

  return (
    <StepContainer message={description}>
      {variablePreparedOptions?.length > 0 && (
        <div className={styles.modelGrid}>
          {variablePreparedOptions.map(option => {
            if (option.hidden) {
              return ""
            }

            const isSelected = values[variableId] === option.value

            return (
              <div
                className={styles.col}
                key={option.value}
                onClick={() =>
                  option.active ? handleSelect(option.value) : ""
                }
                onMouseEnter={() =>
                  option.active ? setHovered(option.value) : ""
                }
                onMouseLeave={() => (option.active ? setHovered(null) : "")}
              >
                <Card
                  hoverd={getHoveredClass(option.value)}
                  selected={isSelected}
                  disabled={!option.active}
                  data={{
                    images: [option.image ?? ""],
                    subtitle: option.description,
                    title: option.text,
                  }}
                />
              </div>
            )
          })}
        </div>
      )}
    </StepContainer>
  )
}

export default Model
