import { useTranslation } from "react-i18next"

import img from "../../assets/404.jpg"
import LinkBack from "../LinkBack/LinkBack"
import LinkButton from "../LinkButton/LinkButton"
import Image from "../image/Image"

import styles from "./Error404.module.scss"

const Error404 = () => {
  const { i18n, t } = useTranslation()

  return (
    <div className={styles.error404Container}>
      <div className={styles.textCointainer}>
        <LinkBack />
        <h1 className={styles.title}>{t("This stop does not exist")}</h1>
        <h2 className={styles.subtitle}>
          {t("The requested page has not been found.")}
        </h2>
        <p className={styles.paragraph}>
          {t(
            "If you typed the URL directly in the browser, check that it is correct. It may have changed address, be temporarily inactive or it may not exist."
          )}
        </p>
        <div className={styles.button}>
          <LinkButton
            to={`/${i18n.language}`}
            children={t("Go to home page")}
          />
        </div>
      </div>

      <Image className={styles.image} images={[img]} alt="" />
    </div>
  )
}

export default Error404
