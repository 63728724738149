import { useLocation } from "react-router-dom"
import { matchPath } from 'react-router';

const useCustom = () => {
  const location = useLocation()

  // the best way I could find to check if the given path is the custom one
  const isCustom = !!matchPath(
    "/:locale/custom/:SCHEMA/:CCO",
    location.pathname
  )

  return { isCustom }
}

export default useCustom
