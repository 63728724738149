import PropTypes from "prop-types"

import styles from "./Error404Wrapper.module.scss"

const Error404Wrapper = ({ children }) => {
  return <div className={styles.Error404Wrapper}>{children}</div>
}

export default Error404Wrapper

Error404Wrapper.propTypes = { children: PropTypes.node }
Error404Wrapper.default = { children: null }
