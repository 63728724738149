import { useContext } from "react"
import { useTranslation } from "react-i18next"

import { AppContext } from "../../../context/AppContext"
import ConfigurationContext from "../../../context/ConfigurationContext"
import VariableClass from "../../../helpers-shared/configuration/variable"
import { findVariable } from "../../../helpers-shared/configuration/variables"
import AreaClass from "../../../helpers/configuration/area"
import { findArea } from "../../../helpers/configuration/areas"
import { getMainWallId } from "../../../helpers/configuration/general"
import {
  calculateIncompatibilities,
  calculateVariableIncompatibilities,
} from "../../../helpers/configuration/incompatibilities"
import { getCmsAssetsUrl } from "../../../helpers/general"
import useScroll from "../../../hooks/use-Scroll"
import Accordion from "../../Accordion/Accordion"
import Button from "../../buttons/Button"
import AllWallsSameControl from "../AllWallsSameControl/AllWallsSameControl"
import AreaHeader from "../AreaHeader/AreaHeader"
import Booleano from "../fields/Booleano/Booleano"
import MuestraCircular from "../fields/MuestraCircular/MuestraCircular"
import MuestraCuadrada from "../fields/MuestraCuadrada/MuestraCuadrada"
import MuestraRectangular from "../fields/MuestraRectangular/MuestraRectangular"
import OpcionesIcono from "../fields/OpcionesIcono/OpcionesIcono"
import OpcionesInline from "../fields/OpcionesInline/OpcionesInline"
import Switch from "../fields/Switch/Switch"

import style from "./Area.module.scss"

const assetsUrl = getCmsAssetsUrl()

const Area = ({ id }) => {
  const { i18n, t } = useTranslation()

  const context = useContext(ConfigurationContext)
  const appContext = useContext(AppContext)

  const { store } = appContext
  const { nextArea, goToArea, allAreas, values } = context

  const handleGoToNext = () => {
    goToArea(nextArea)
  }
  const y = useScroll()
  document.documentElement.style.setProperty("--scroll", `${y}px`)

  const renderArea = (areaObj, level = 0) => {
    if (areaObj.getId() === "PAREDES" && values.all_walls_same) {
      return renderAllWallsVariable()
    }

    const hijos = areaObj.getHijos()

    if (!hijos || hijos.length <= 0) {
      const displayInTab = areaObj.displayInTab()
      const areaName = areaObj.getName(i18n.language)
      const showTitle = level > 0

      if (displayInTab) {
        return (
          <Accordion
            key={`subarea-${areaObj.getId()}`}
            id={`subarea-${areaObj.getId()}`}
            title={areaName}
          >
            {renderVariables(areaObj)}
          </Accordion>
        )
      }

      return (
        <div className={style.subarea} key={`subarea-${areaObj.getId()}`}>
          {showTitle && <h2>{areaName}</h2>}
          {renderVariables(areaObj)}
        </div>
      )
    }

    return hijos.map(hijo => {
      const [hijoId, hijoData] = hijo

      const hijoObj = AreaClass(hijoId, hijoData)

      return renderArea(hijoObj, level + 1)
    })
  }

  const renderVariables = areaObj => {
    const variables = areaObj.getVariables()

    return Object.entries(variables).map(variable => renderVariable(variable))
  }

  const renderAllWallsVariable = () => {
    // Get data from one wall variable
    const mainWallId = getMainWallId(values)
    const mainWallVariableData = findVariable(store.variables, mainWallId)

    // Duplicate it and fill with "All walls" variable data that doesn´t exist in config files
    const allWallsName = t("All walls")
    const mainWallsVariableData = [
      mainWallId,
      {
        ...mainWallVariableData,
        NOMBRE: {
          en: allWallsName,
          es: allWallsName,
          eu: allWallsName,
          de: allWallsName,
          fr: allWallsName,
          it: allWallsName,
          nl: allWallsName,
          no: allWallsName,
          pl: allWallsName,
          pt: allWallsName,
          ru: allWallsName,
        },
      },
    ]

    return renderVariable(mainWallsVariableData)
  }

  const renderVariable = variable => {
    const [variableId, variableData] = variable

    const variableObj = VariableClass(variableId, variableData)

    const variableName = variableObj.getName(i18n.language)
    const displayInTab = variableObj.displayInTab()
    const variableIsActive = variableObj.isActive()

    if (!variableIsActive) {
      return ""
    }

    if (displayInTab) {
      return (
        <Accordion
          key={`variable-${variableId}`}
          id={`variable-${variableId}`}
          title={variableName}
        >
          <div className={style.variable}>
            {renderVariableOptions(variableObj)}
          </div>
        </Accordion>
      )
    }

    return (
      <div className={style.variable} key={`variable-${variableId}`}>
        {renderVariableOptions(variableObj)}
      </div>
    )
  }

  const renderVariableOptions = variableObj => {
    const variableId = variableObj.getId()
    const variableName = variableObj.getName(i18n.language)
    const displayLabel = variableObj.displayLabel()
    const isMultiselect = variableObj.isMultiselect()
    const visualizacion = variableObj.getVisualizacion()
    const variablePreparedOptions = variableObj.getPreparedOptions(
      i18n.language,
      { assetsUrl }
    )

    if (!visualizacion) {
      return ""
    }

    let variableOptions
    let preparedOptions
    if (visualizacion === "BOOLEANO") {
      variableOptions = calculateVariableIncompatibilities(
        values,
        variableId,
        store.incompatibilities
      )
    } else {
      preparedOptions = calculateIncompatibilities(
        values,
        variableId,
        variablePreparedOptions,
        store.incompatibilities
      )
    }

    switch (visualizacion) {
      case "OPCIONES_ICONO":
        return (
          <OpcionesIcono
            title={displayLabel ? variableName : ""}
            name={variableId}
            options={preparedOptions}
            multiselect={isMultiselect}
          />
        )
      case "MUESTRA_CIRCULAR":
        return (
          <MuestraCircular
            title={displayLabel ? variableName : ""}
            name={variableId}
            options={preparedOptions}
            multiselect={isMultiselect}
          />
        )
      case "MUESTRA_CUADRADA":
        return (
          <MuestraCuadrada
            title={displayLabel ? variableName : ""}
            name={variableId}
            options={preparedOptions}
            multiselect={isMultiselect}
          />
        )
      case "MUESTRA_RECTANGULAR":
        return (
          <MuestraRectangular
            title={displayLabel ? variableName : ""}
            name={variableId}
            options={preparedOptions}
            multiselect={isMultiselect}
          />
        )
      case "SWITCH":
        return (
          <Switch
            title={displayLabel ? variableName : ""}
            name={variableId}
            options={preparedOptions}
          />
        )
      case "OPCIONES_INLINE":
        return (
          <OpcionesInline
            title={displayLabel ? variableName : ""}
            name={variableId}
            options={preparedOptions}
            multiselect={isMultiselect}
          />
        )
      case "BOOLEANO":
        return (
          <Booleano
            label={variableName}
            name={variableId}
            {...variableOptions}
          />
        )
      default:
        return ""
    }
  }

  // Data for the next area
  const nextAreaData = findArea(allAreas, nextArea)
  const nextAreaObj = AreaClass(nextArea, nextAreaData)
  const nextName = nextAreaObj.getName(i18n.language)

  // Data for the current area
  const areaData = findArea(allAreas, id)
  const areaObj = AreaClass(id, areaData)
  const name = areaObj.getName(i18n.language)
  const description = areaObj.getDescription(i18n.language)
  const icon = areaObj.getImage(assetsUrl)

  return (
    <div className={[style.area, y > 0 ? style.isScrolling : " "].join(" ")}>
      <div className={style.contentArea}>
        <AreaHeader title={name} description={description} icon={icon} />

        {id === "PAREDES" && <AllWallsSameControl />}

        {renderArea(areaObj)}

        {nextArea && (
          <div className={style.areabuttonNext}>
            <Button type="button" onClick={handleGoToNext}>
              {t("Next")} - {nextName}
            </Button>
          </div>
        )}
        {!nextArea && <div className={style.fakeSpace}></div>}
      </div>
    </div>
  )
}

export default Area
