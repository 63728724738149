import { getParedAcabado, isGlazedWall } from "../configuration/utils"

// TODO: calculate dominant side, get first wall with panel, clockwise
const getDominantSide = values => {
  const sidesClockwise = ["IZQUIERDA", "FRONTAL", "DERECHA"]

  const found = sidesClockwise.find(side => {
    return values.CCAB_PANEL_MANDO_POSICION.includes(side)
  })

  if (!found) {
    return ""
  }

  return found
}

const getParedAcabadoLayer = values => {
  const side = getDominantSide(values)
  const paredAcabado = getParedAcabado(side, values)

  if (!paredAcabado) {
    return null
  }

  return {
    folder: "PAREDES",
    imageName: paredAcabado,
    withPlaceholder: true,
  }
}

const getPanelMandoMaterialLayer = values => {
  const imageName = `${values.CCAB_PANEL_MANDO_MATERIAL}`

  return {
    folder: "PANEL_MANDO_MATERIAL",
    imageName: imageName,
  }
}

const getPanelMandoCabinaSenalLayer = values => {
  const imageName = `${values.CCAB_PANEL_MANDO_CABINA_SENAL}`

  return {
    folder: "PANEL_MANDO_CABINA_SENAL",
    imageName: imageName,
  }
}

const getPanelMandoCabinaPulsadorLayer = values => {
  const imageName = `${values.CCAB_PANEL_MANDO_SENAL_CABINA_PULSADOR}`

  return {
    folder: "PANEL_MANDO_SENAL_CABINA_PULSADOR",
    imageName: imageName,
  }
}

const getPanelMandoCabinaPulsadorContrasteLayer = () => {
  const imageName = `CONTRASTE`

  return {
    folder: "PANEL_MANDO_SENAL_CABINA_PULSADOR_CONTRASTE",
    imageName: imageName,
  }
}

const prepareLayers = values => {
  const layers = []

  const side = getDominantSide(values)
  const paredAcabado = getParedAcabado(side, values)

  if (isGlazedWall(paredAcabado)) {
    layers.push({ folder: "FONDO", imageName: "FONDO" })
  }

  // Pared
  layers.push(getParedAcabadoLayer(values))

  // Panel mando material
  layers.push(getPanelMandoMaterialLayer(values))

  // Panel mando señalización
  layers.push(getPanelMandoCabinaSenalLayer(values))

  // Panel mando pulsador
  layers.push(getPanelMandoCabinaPulsadorLayer(values))

  // Panle mando contraste
  if (values.CCAB_PANEL_MANDO_CONTRASTE) {
    layers.push(getPanelMandoCabinaPulsadorContrasteLayer())
  }

  return layers
}

export { prepareLayers }
