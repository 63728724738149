import React from "react"
import { useTranslation } from "react-i18next"

import PropTypes from "prop-types"

import styles from "./Footer.module.scss"

const Footer = ({ areaB }) => {
  const { t } = useTranslation()
  return (
    <footer className={styles.footer}>
      <div className={styles.areaLeft}>
        <p className={styles.copyright}>
          &copy; Orona S.Coop. {t("All Rights Reserved")}
        </p>
      </div>
      <div className={styles.areaRight}>{areaB}</div>
    </footer>
  )
}
export default Footer
Footer.propTypes = {
  areaB: PropTypes.node,
}
Footer.defaultProps = {
  areaB: PropTypes.node,
}
