import AreaClass from "./area"

const getFirstStepsAreas = allAreas => {
  if (!allAreas || Object.entries(allAreas).length <= 0) {
    return null
  }

  const found = Object.entries(allAreas).filter(item => {
    const [id, areaData] = item
    const areaObj = AreaClass(id, areaData)

    return areaObj.isFirstStepsArea()
  })

  return Object.fromEntries(found)
}

const getConfiguratorAreas = allAreas => {
  if (!allAreas || Object.entries(allAreas).length <= 0) {
    return null
  }

  const found = Object.entries(allAreas).filter(item => {
    const [id, areaData] = item
    const areaObj = AreaClass(id, areaData)

    return areaObj.isConfiguratorArea()
  })

  return Object.fromEntries(found)
}

const getHomeStepsAreas = allAreas => {
  if (!allAreas || Object.entries(allAreas).length <= 0) {
    return null
  }

  const found = Object.entries(allAreas).filter(item => {
    const [id, areaData] = item
    const areaObj = AreaClass(id, areaData)

    return areaObj.isHomeStepsArea()
  })

  return Object.fromEntries(found)
}

const getAreaSequence = areas => {
  if (!areas || areas.length <= 0) {
    return null
  }

  return Object.keys(areas)
}

const findArea = (areas, areaId) => {
  if (!areaId || !areas || areas.length <= 0) {
    return null
  }

  let found = areas[areaId] ?? null

  if (!found) {
    return null
  }

  return { ID: areaId, ...found }
}

const findAreaPath = (areas, searchAreaId) => {
  const toObj = data => {
    return data.reduce((accumulator, currentValue) => {
      return { ...accumulator, ...currentValue }
    }, {})
  }

  const buildPath = (areaEntry, path) => {
    const [areaId, areaData] = areaEntry

    path = path || []
    const areaPath = [...path, { [areaId]: areaData }]

    let children = []

    if (Object.entries(areaData.HIJOS).length > 0) {
      children = Object.entries(areaData.HIJOS).map(i => buildPath(i, areaPath))
    }

    const childrenPaths = toObj(children)

    return { [areaId]: { path: areaPath, children: childrenPaths } }
  }

  const findPathRecursive = (areas, searchAreaId) => {
    const areaEntries = Object.entries(areas)

    let found

    for (const entry of areaEntries) {
      const [areaId, areaData] = entry

      if (areaId === searchAreaId) {
        found = areaData.path
      } else if (Object.entries(areaData.children).length > 0) {
        found = findPathRecursive(areaData.children, searchAreaId)
      }

      if (found) {
        return found
      }
    }

    return null
  }

  const pathArray = Object.entries(areas).map(i => buildPath(i))
  const paths = toObj(pathArray)

  const result = findPathRecursive(paths, searchAreaId)

  return result
}

const getActiveAreas = areas => {
  const activeAreas = Object.entries(areas).filter(area => {
    const [areaId, areaData] = area

    const areaObj = AreaClass(areaId, areaData)

    return areaObj.isActive()
  })

  return Object.fromEntries(activeAreas)
}

const getPrevArea = (areas, areaId) => {
  if (!areaId || !areas || areas.length <= 0) {
    return null
  }

  const activeAreas = getActiveAreas(areas)

  const areaSequence = getAreaSequence(activeAreas)

  const position = areaSequence.indexOf(areaId)

  if (position === -1 || position - 1 < 0) {
    return null
  }

  return areaSequence[position - 1]
}

const getNextArea = (areas, areaId) => {
  if (!areaId || !areas || areas.length <= 0) {
    return null
  }

  const activeAreas = getActiveAreas(areas)

  const areaSequence = getAreaSequence(activeAreas)

  const position = areaSequence.indexOf(areaId)

  if (position === -1 || position + 1 > areaSequence.length - 1) {
    return null
  }

  return areaSequence[position + 1]
}

const getFirstActiveHomeStepNumber = (areas = null) => {
  if (!areas) {
    return null
  }

  const steps = Object.entries(areas).map((item, index) => {
    const [areaId, areaData] = item
    const areaObj = AreaClass(areaId, areaData)

    return {
      number: index + 1,
      canVisit: areaObj.isActive(),
    }
  })

  const firstAvailableStep = steps.find(item => item.canVisit)

  if (!firstAvailableStep) {
    return null
  }

  return firstAvailableStep.number
}

export {
  getFirstStepsAreas,
  getConfiguratorAreas,
  getHomeStepsAreas,
  getFirstActiveHomeStepNumber,
  getAreaSequence,
  findArea,
  findAreaPath,
  getPrevArea,
  getNextArea,
}
