import i18n from "i18next"

const validateTecho = values => {
  const { t } = i18n

  const errors = {}

  if (!values.CCAB_TECHO_MODELO) {
    errors.CCAB_TECHO_MODELO = t("Please select an option.")
  }

  if (!values.CCAB_TECHO_MATERIAL) {
    errors.CCAB_TECHO_MATERIAL = t("Please select an option.")
  }

  return errors
}

export default validateTecho
