import React from "react"

import PropTypes from "prop-types"

import styles from "./Button.module.scss"

/**
 * Primary UI component for user interaction
 */
export const Button = ({ primary, size, children, ...props }) => {
  const mode = primary ? styles.buttonPrimary : styles.buttonSecondary
  return (
    <button
      type="button"
      className={[styles.button, styles.buttonSize + { size }, mode].join(" ")}
      {...props}
    >
      {children}
    </button>
  )
}
export default Button

Button.propTypes = {
  primary: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  primary: false,
  onClick: undefined,
}
