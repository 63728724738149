import { useContext } from "react"
import { useTranslation } from "react-i18next"

import { CasUserContext } from "../context/CasUserContext"

const useReloadWarning = (display = true) => {
  const { t } = useTranslation()

  const casUserContext = useContext(CasUserContext)
  const isLoggedIn = casUserContext.user !== null

  const handleReloadWarning = (event, message = null) => {
    if (!display || !isLoggedIn) {
      return true
    }

    const warning =
      message || t("Are you sure? Changes that you made may not be saved.")

    if (!window.confirm(warning)) {
      event.preventDefault()
    }
  }

  return { handleReloadWarning }
}

export default useReloadWarning
