import React, { useContext, useEffect, useState } from "react"

import views from "../data/all-views.json"
import { getDataConfigsUrl } from "../helpers/general"
import { CasUserContext } from "./CasUserContext"

export const AppContext = React.createContext(null)

const dataUrl = getDataConfigsUrl()

// Do cache busting of config files every time the site gets loaded
const cacheBustVersion = `?v=${Date.now()}`

const configFiles = {
  categories: "/configs/category-tree.json" + cacheBustVersion,
  variables: "/configs/all-variables.json" + cacheBustVersion,
  incompatibilities: "/configs/no-compatibilities.json" + cacheBustVersion,
  predesigns: "/configs/pre-designs.json" + cacheBustVersion,
  defaults: "/configs/default-values.json" + cacheBustVersion,
}

export const ContextWrapper = props => {
  const initialState = initState()

  const [store, setStore] = useState(initialState)

  const casUserContext = useContext(CasUserContext)

  useEffect(() => {
    if (casUserContext.withAuth && !casUserContext.user) {
      return false
    }

    fetchConfigs().then(data => {
      const processedCategories = processCategories(data)

      const processedData = { ...data, categories: processedCategories }

      setStore(prevStore => {
        return { ...prevStore, ...processedData }
      })
    })
  }, [casUserContext.user, casUserContext.withAuth])

  return (
    <AppContext.Provider value={{ store }}>
      {props.children}
    </AppContext.Provider>
  )
}

const initState = () => {
  let obj = {}

  for (const property in configFiles) {
    obj[property] = null
  }

  // Load views from imported local file
  obj["views"] = views

  return obj
}

// Get variables for each category
const processCategories = data => {
  if (!data || !data.categories) {
    return null
  }

  const processed = Object.entries(data.categories).map(category =>
    processCategory(category, data.variables)
  )

  return Object.fromEntries(processed)
}

const processCategory = (category, allVariables) => {
  const [categoryId, categoryData] = category

  if (Object.entries(categoryData.HIJOS).length <= 0) {
    const variables = getCategoryVariables(category, allVariables)

    const processedCategory = [
      categoryId,
      { ...categoryData, VARIABLES: variables },
    ]

    return processedCategory
  }

  const processedHijos = Object.entries(categoryData.HIJOS).map(childCategory =>
    processCategory(childCategory, allVariables)
  )

  const processedCategory = [
    categoryId,
    { ...categoryData, HIJOS: Object.fromEntries(processedHijos) },
  ]

  return processedCategory
}

const getCategoryVariables = (category, allVariables) => {
  const [categoryId] = category

  const variables = Object.entries(allVariables).filter(variable => {
    const [, variableData] = variable

    return variableData.CATEGORIA === categoryId
  })

  return Object.fromEntries(variables)
}

const fetchConfigs = async () => {
  const allData = await Promise.all(
    Object.keys(configFiles).map(async key => {
      const fileName = configFiles[key]
      const url = `${dataUrl}${fileName}`

      const response = await fetch(url)
      const data = await response.json()

      return { [key]: data }
    })
  )

  return allData.reduce((accumulator, currentValue) => {
    return { ...accumulator, ...currentValue }
  })
}
