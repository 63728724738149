import React from "react"

import PropTypes from "prop-types"

import { getCacheBustVersionParam } from "../../helpers/general"

// import styles from "./Image.module.scss"

const cacheBustVersion = getCacheBustVersionParam()

const isProduction = process.env.NODE_ENV === "production"

//TODO : https://css-tricks.com/preventing-content-reflow-from-lazy-loaded-images/
const LayerImage = ({ alt, className, images, style, on_load, loading }) => {
  if (
    !images ||
    !Array.isArray(images) ||
    images.length <= 0 ||
    images.join("").length === 0
  ) {
    return ""
  }
  return images.length > 1 ? (
    <picture
      className={className}
      style={style}
      onLoad={on_load}
      onError={() =>
        !isProduction ? console.error(`Error loading: ${images}`) : ""
      }
    >
      {images[2] && (
        <source
          media="(max-width: 769px)"
          srcSet={`${images[2]}.webp` + cacheBustVersion}
          type="image/webp"
        />
      )}
      {images[1] && (
        <source
          media="(max-width: 1360px)"
          srcSet={`${images[1]}.webp` + cacheBustVersion}
          type="image/webp"
        />
      )}
      <source
        media="(min-width: 1361px)"
        srcSet={`${images[0]}.webp` + cacheBustVersion}
        type="image/webp"
      />

      {images[2] && (
        <source
          media="(max-width: 769px)"
          srcSet={`${images[2]}.png` + cacheBustVersion}
          type="image/png"
        />
      )}
      {images[1] && (
        <source
          media="(max-width: 1360px)"
          srcSet={`${images[1]}.png` + cacheBustVersion}
          type="image/png"
        />
      )}
      <source
        media="(min-width: 1361px)"
        srcSet={`${images[0]}.png` + cacheBustVersion}
        type="image/png"
      />

      <img
        src={`${images[0]}.png` + cacheBustVersion}
        alt={alt}
        loading={loading}
      />
    </picture>
  ) : (
    <img
      loading={loading}
      onLoad={on_load}
      style={style}
      src={`${images[0]}.png` + cacheBustVersion}
      className={className}
      alt={alt}
    />
  )
}

export default LayerImage

LayerImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.object,
  images: PropTypes.array,
  style: PropTypes.object,
  loading: PropTypes.func,
  onLoad: PropTypes.func,
}

LayerImage.defaultProps = {
  alt: null,
  images: null,
}
