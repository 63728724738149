import { useContext } from "react"

import ConfigurationContext from "../../../../context/ConfigurationContext"
import {
  getNextView,
  getPrevView,
} from "../../../../helpers/configuration/views"

import style from "./ViewNavigation.module.scss"

import useCCR from "../../../../hooks/use-CCR"

const ViewNavigation = () => {
  const context = useContext(ConfigurationContext)

  const { currentView, switchView, views } = context
  const { isCCR } = useCCR()

  if (!views) {
    return ""
  }

  // disable outside view on CCR
  views.EXTERIOR.ACTIVO = views.EXTERIOR && !isCCR

  const prevView = getPrevView(views, currentView)
  const nextView = getNextView(views, currentView)

  if (prevView === currentView && nextView === currentView) {
    return ""
  }

  return (
    <>
      <div className={[style.viewNavigation, style.left].join(" ")}>
        {prevView && prevView !== currentView && (
          <button
            type="button"
            className={style.prev}
            onClick={() => switchView(prevView)}
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.77193 5.6985L7.56358 1.90685L5.65672 -2.43792e-06L-0.000130707 5.65685L1.90672 7.56371L1.90674 7.56369L5.65675 11.3137L7.52195 9.44851L3.77193 5.6985Z"
                fill="black"
              />
            </svg>
          </button>
        )}
      </div>
      <div className={[style.viewNavigation, style.right].join(" ")}>
        {nextView && nextView !== currentView && (
          <button
            type="button"
            className={style.next}
            onClick={() => switchView(nextView)}
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.22808 6.30149L0.436437 10.0931L2.34329 12L8.00014 6.34313L6.09329 4.43628L6.09328 4.43629L2.34326 0.686279L0.478068 2.55147L4.22808 6.30149Z"
                fill="black"
              />
            </svg>
          </button>
        )}
      </div>
    </>
  )
}

export default ViewNavigation
