const API = {
  root: "/api/v1",
  image: "/image",
  pdf: "/pdf",
  quote: "/quote",
}

const getDataAssetsUrl = () => {
  return process.env.REACT_APP_DATA_ASSETS_URL || ""
}

const getDataConfigsUrl = () => {
  return process.env.REACT_APP_DATA_CONFIGS_URL || ""
}

const getCmsAssetsUrl = () => {
  return process.env.REACT_APP_CMS_ASSETS_URL || ""
}

const getApiEndpointUrl = (endpoint = "") => {
  if (!endpoint || !(endpoint in API)) {
    return ""
  }

  return `${API.root}${API[endpoint]}`
}

const getLambdaUrl = () => {
  return process.env.REACT_APP_LAMBDA_URL || ""
}

const getWithAuth = async () => {
  const cacheBustVersion = getCacheBustVersionParam()

  const dataUrl = getDataConfigsUrl()
  const url = `${dataUrl}/config.json${cacheBustVersion}`

  try {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`HTTP error: ${response.status}`)
    }

    const data = await response.json()

    if (!data.REACT_APP_WITH_AUTH) {
      return false
    }

    return data.REACT_APP_WITH_AUTH === true
  } catch (error) {
    console.error(`Could not get withAuth value: ${error}`)

    return false
  }
}

const getCasEndpoint = async () => {
  const cacheBustVersion = getCacheBustVersionParam()

  const dataUrl = getDataConfigsUrl()
  const url = `${dataUrl}/config.json${cacheBustVersion}`

  try {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`HTTP error: ${response.status}`)
    }

    const data = await response.json()

    if (!data.REACT_APP_CAS_ENDPOINT) {
      throw new Error(`Could not get CAS endpoint`)
    }

    return data.REACT_APP_CAS_ENDPOINT ?? null
  } catch (error) {
    console.error(`Could not get CAS endpoint: ${error}`)

    return null
  }
}

const getSiteTitle = (viewer = false) => {
  if (viewer) {
    return process.env.REACT_APP_VIEWER_TITLE || "Orona Ambiences Viewer"
  }

  return process.env.REACT_APP_SITE_TITLE || "Orona Ambiences"
}

const getCacheBustVersion = () => {
  return process.env.REACT_APP_CACHE_BUST || ""
}

const getCacheBustVersionParam = () => {
  const cacheBustVersion = getCacheBustVersion()

  return cacheBustVersion ? `?v=${cacheBustVersion}` : ""
}

const getEnabledLanguages = locales => {
  if (!locales || Object.entries(locales).length <= 0) {
    return null
  }

  const enabled = Object.entries(locales).filter(locale => {
    const [, localeData] = locale

    return localeData.enabled
  })

  if (!enabled || enabled.length <= 0) {
    return null
  }

  return Object.fromEntries(enabled)
}

const getSortedLanguages = locales => {
  if (!locales || Object.entries(locales).length <= 0) {
    return null
  }

  const sorted = Object.entries(locales).sort((a, b) => {
    const [, dataA] = a
    const [, dataB] = b

    const nameA = dataA.nativeName.toLowerCase()
    const nameB = dataB.nativeName.toLowerCase()

    if (nameA < nameB) {
      return -1
    }

    if (nameA > nameB) {
      return 1
    }

    return 0
  })

  if (!sorted || sorted.length <= 0) {
    return null
  }

  return Object.fromEntries(sorted)
}

const getDefaultLanguage = locales => {
  if (!locales || Object.entries(locales).length <= 0) {
    return null
  }

  const enabledLanguages = getEnabledLanguages(locales)

  const found = Object.entries(enabledLanguages).find(locale => {
    const [, localeData] = locale

    return localeData.default
  })

  if (!found || found.length <= 0) {
    return null
  }

  return found[0]
}

const stripHtml = (html = "") => {
  let tmp = document.createElement("DIV")
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ""
}

const str2bool = (value = "yes") => {
  if (value && typeof value === "string") {
    if (value.toLowerCase() === "yes") {
      return true
    }
    if (value.toLowerCase() === "no") {
      return false
    }
  }
  return value
}

const isInList = (list = [], value = "") => {
  if (!list || list.length <= 0 || !value) {
    return false
  }

  const lowerCaseList = list.map(i => i.toLowerCase())
  const lowerCaseValue = value.toLowerCase()

  return lowerCaseList.includes(lowerCaseValue)
}

export {
  getDataAssetsUrl,
  getDataConfigsUrl,
  getCmsAssetsUrl,
  getApiEndpointUrl,
  getLambdaUrl,
  getWithAuth,
  getCasEndpoint,
  getSiteTitle,
  getCacheBustVersion,
  getCacheBustVersionParam,
  getEnabledLanguages,
  getSortedLanguages,
  getDefaultLanguage,
  stripHtml,
  str2bool,
  isInList,
}
