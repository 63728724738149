const calculateIncompatibilities = (
  values,
  variableId,
  preparedOptions,
  allRules
) => {
  if (!preparedOptions) {
    return null
  }

  return preparedOptions.map(option => {
    const affectingConditions = getOptionAffectingConditions(
      variableId,
      option,
      values,
      allRules
    )

    if (affectingConditions.length <= 0) {
      return option
    }

    return applyCondition(option, affectingConditions)
  })
}

const calculateVariableIncompatibilities = (values, variableId, allRules) => {
  const affectingConditions = getOptionAffectingConditions(
    variableId,
    null,
    values,
    allRules
  )

  return applyVariableCondition(affectingConditions)
}

const applyCondition = (option, affectingConditions) => {
  const types = affectingConditions
    .map(condition =>
      condition.INCOMPATIBILIDADES.map(restriction => restriction.TYPE).flat()
    )
    .flat()

  const uniqueTypes = [...new Set(types)]

  if (uniqueTypes.some(type => type === "OCULTO")) {
    return { ...option, hidden: true }
  }

  if (
    uniqueTypes.some(
      type =>
        type === "DESACTIVADO" ||
        type === "DESACTIVADO_TODO" ||
        type === "DESACTIVADO_MULTIPLE"
    )
  ) {
    return { ...option, disabled: true }
  }

  return option
}

const applyVariableCondition = affectingConditions => {
  const types = affectingConditions
    .map(condition =>
      condition.INCOMPATIBILIDADES.map(restriction => restriction.TYPE).flat()
    )
    .flat()

  const uniqueTypes = [...new Set(types)]

  if (uniqueTypes.some(type => type === "DESACTIVADO_VARIABLE")) {
    return { disabled: true }
  }

  if (uniqueTypes.some(type => type === "OCULTO_VARIABLE")) {
    return { hidden: true }
  }

  return {}
}

const areConditionsMet = (values, conditions) => {
  return conditions.every(condition => {
    const value = values[condition.ID_PROPIEDAD]

    if (condition.TYPE && condition.TYPE === "IS_ONE_OF") {
      return isOneOf(condition.ID_VALOR, value)
    }

    if (Array.isArray(value) && condition.ID_VALOR === "") {
      return value.length <= 0
    }

    if (Array.isArray(value)) {
      return values[condition.ID_PROPIEDAD].includes(condition.ID_VALOR)
    }

    return value === condition.ID_VALOR
  })
}

const getOptionAffectingConditions = (
  variableId,
  option = null,
  values,
  allRules
) => {
  const affecting = allRules.filter(rule => {
    const conditionsMet = areConditionsMet(values, rule.CONDICIONES)

    if (!conditionsMet) {
      return false
    }

    if (!option) {
      const variableIsInList = variableInIncompatibilities(
        variableId,
        rule.INCOMPATIBILIDADES
      )

      return conditionsMet && variableIsInList
    }

    const optionIsInList = optionInIncompatibilities(
      variableId,
      option.value,
      rule.INCOMPATIBILIDADES
    )

    return conditionsMet && optionIsInList
  })

  const filteredAffecting = affecting.map(rule => {
    const optionRestriction = rule.INCOMPATIBILIDADES.filter(restriction => {
      if (!option) {
        return true
      }

      if (
        restriction.ID_PROPIEDAD === variableId &&
        restriction.TYPE === "DESACTIVADO_TODO"
      ) {
        return true
      }

      if (
        restriction.ID_PROPIEDAD === variableId &&
        restriction.TYPE === "DESACTIVADO_MULTIPLE"
      ) {
        return isOneOf(restriction.ID_VALOR, option.value)
      }

      return (
        restriction.ID_PROPIEDAD === variableId &&
        restriction.ID_VALOR === option.value
      )
    })

    return { ...rule, INCOMPATIBILIDADES: optionRestriction }
  })

  return filteredAffecting
}

const optionInIncompatibilities = (
  variableId,
  optionValue,
  incompatibilities
) => {
  return incompatibilities.some(item => {
    if (item.ID_PROPIEDAD === variableId && item.TYPE === "DESACTIVADO_TODO") {
      return true
    }

    if (
      item.ID_PROPIEDAD === variableId &&
      item.TYPE === "DESACTIVADO_MULTIPLE"
    ) {
      return isOneOf(item.ID_VALOR, optionValue)
    }

    return item.ID_PROPIEDAD === variableId && item.ID_VALOR === optionValue
  })
}

const variableInIncompatibilities = (variableId, incompatibilities) => {
  return incompatibilities.some(item => {
    return item.ID_PROPIEDAD === variableId
  })
}

export { calculateIncompatibilities, calculateVariableIncompatibilities }

const isOneOf = (oneOfValues, currentValue) => {
  if (
    !currentValue ||
    (Array.isArray(currentValue) && currentValue.length <= 0)
  ) {
    return false
  }

  const oneOfValuesArray = oneOfValues.split(",").map(i => i.trim())

  if (Array.isArray(currentValue)) {
    // Check if all values in "currentValue" array are in "oneOfValuesArray"
    return currentValue.every(i => oneOfValuesArray.includes(i))
  }

  return oneOfValuesArray.includes(currentValue)
}
