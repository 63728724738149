import { useEffect, useState } from "react"

const usePreloadImages = (srcArray = []) => {
  const [isLoading, setIsLoading] = useState(true)

  const cacheImages = async srcArray => {
    if (!srcArray || srcArray.length <= 0) {
      setIsLoading(false)
      return
    }

    const promises = await srcArray.map(src => {
      return new Promise(function (resolve, reject) {
        const img = new Image()

        img.src = src
        img.onload = resolve()
        img.onerror = reject()
      })
    })

    await Promise.all(promises)

    setTimeout(() => {
      setIsLoading(false)
    }, 300)
  }

  useEffect(() => {
    if (!srcArray || srcArray.length <= 0) {
      return
    }

    cacheImages(srcArray).then(() => {})
  }, [srcArray])

  return { isLoading }
}

export default usePreloadImages
