import PropTypes from "prop-types"

import { Field, useFormikContext } from "formik"

import {
  getValueForCheckbox,
  updateAllDependentValues,
} from "../../../../helpers/configuration/resets/resetDependencies"
import Tooltip from "../../../Tooltip/Tooltip"
import ErrorMessage from "../ErrorMessage/ErrorMessage"

import styles from "./MuestraRectangular.module.scss"

const MuestraRectangular = props => {
  const { title, name, options, multiselect } = props

  const formik = useFormikContext()
  const { values, errors, handleChange, setValues } = formik

  const handleChangeField = event => {
    handleChange(event)

    const value = multiselect
      ? getValueForCheckbox(event, values)
      : event.target.value

    setValues(updateAllDependentValues(name, value, formik))
  }

  const fieldValue = values[name]

  const getIsChecked = value => {
    if (!value) {
      return false
    }

    if (multiselect) {
      return fieldValue.includes(value)
    }

    return fieldValue === value
  }

  if (!options || options.length <= 0 || options.every(o => o.hidden)) {
    return ""
  }

  return (
    <>
      {errors[name] && (
        <div className={styles.errorMessage}>
          <ErrorMessage name={name} />
        </div>
      )}

      <div className={styles.content}>
        {title && <p className={styles.titleField}>{title}</p>}
        <ul className={styles.listChecks}>
          {options.map(option => {
            const {
              text: optionText,
              value: optionValue,
              description: optionDescription,
              disabled: optionDisabled = false,
              hidden: optionHidden = false,
              image: optionImage,
            } = option

            if (optionHidden) {
              return ""
            }

            const isChecked = getIsChecked(optionValue)
            const isDisabled = !option.active || optionDisabled

            return (
              <li key={`field-${name}-option-${optionText}-${optionValue}`}>
                <span className={[styles.contentLabel].join(" ")}>
                  <span
                    className={[
                      styles.forImage,
                      isDisabled ? styles.disabled : " ",
                      isChecked ? styles.checked : " ",
                    ].join(" ")}
                  >
                    <label
                      className={[styles.label].join(" ")}
                      data-for={`field-${name}-option-${optionValue}`}
                      data-tip={optionDescription}
                    >
                      <img src={optionImage} alt={optionText} />

                      <Tooltip
                        place="top"
                        identificador={`field-${name}-option-${optionValue}`}
                      />

                      {multiselect ? (
                        <Field
                          disabled={isDisabled}
                          className={styles.input}
                          type="checkbox"
                          name={name}
                          value={optionValue}
                          onChange={handleChangeField}
                        />
                      ) : (
                        <Field
                          disabled={isDisabled}
                          className={styles.input}
                          type="radio"
                          name={name}
                          value={optionValue}
                          onChange={handleChangeField}
                        />
                      )}
                    </label>
                  </span>
                </span>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default MuestraRectangular

MuestraRectangular.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
      image: PropTypes.string,
      disabled: PropTypes.bool,
      hidden: PropTypes.bool,
      isGroupTitle: PropTypes.bool,
    })
  ),
  multiselect: PropTypes.bool,
}

MuestraRectangular.defaultProps = {
  title: "",
  name: "",
  options: [],
  multiselect: false,
}
