import React, { useContext, useState } from "react"
import { useMediaQuery } from "react-responsive"

import PropTypes from "prop-types"

import { Navigation } from "swiper"

// Pagination module
import { Swiper, SwiperSlide } from "swiper/react"
// core Swiper
import "swiper/scss"
import "swiper/scss/navigation"
import "swiper/scss/pagination"

import HomeStepsContext from "../../../context/HomeStepsContext"
import AreaClass from "../../../helpers/configuration/area"
import { findArea } from "../../../helpers/configuration/areas"
import Card from "../../card/Card"

import "../../../styles/slider-customize.scss"
import styles from "./PresetDesings.module.scss"

const Large = ({ children }) => {
  const isMobile = useMediaQuery({
    query: "(min-width:  993px)",
  })
  return isMobile ? children : null
}
const Mobil = ({ children }) => {
  const isMobile = useMediaQuery({
    query: "(max-width:  992px)",
  })
  return isMobile ? children : null
}

const PresetDesing = ({ data, onClick }) => {
  // store swiper instances
  const { text, description, image, children } = data

  const [hovered, setHovered] = useState(null)

  const [slideChange, setSlideChange] = useState(null)
  const [begginner, setIsBegginer] = useState(null)

  const context = useContext(HomeStepsContext)

  const { step, steps, values, allAreas } = context

  if (!steps || steps.length <= 0) {
    return ""
  }

  const stepData = steps.find(s => s.number === step)
  const areaId = stepData["areaId"]

  if (!areaId) {
    return ""
  }

  // Data for the current area
  const areaData = findArea(allAreas, areaId)
  const areaObj = AreaClass(areaId, areaData)
  const variable = areaObj.getFirstVariable()

  if (!variable) {
    return null
  }

  const [variableId] = variable

  const squareStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
  }

  const handleSelect = id => {
    onClick(id)
  }

  const getSliderChangeClass = () => {
    if (!slideChange) {
      return ""
    }
    if (begginner) {
      return styles.isBeginning
    }
    return styles.slideChange
  }

  const isHovered = id => {
    if (!hovered) {
      return false
    }
    if (hovered && hovered === id) {
      return false
    }

    return true
  }

  return (
    <article className={styles.block}>
      <Large>
        <div
          className={[
            styles.cardTitleBlock,
            getSliderChangeClass(),
            begginner ? styles.vuelta : " ",
          ].join(" ")}
        >
          <div className={styles.square} style={squareStyle}>
            <div className={styles.texts}>
              <h2 className={styles.titulo}>{text}</h2>
              <p className={styles.text}>{description}</p>
            </div>
          </div>
        </div>
      </Large>

      <Mobil>
        {" "}
        <h2 className={styles.tituloMobil}>{text}</h2>
      </Mobil>
      <div className={[styles.contentSlider, getSliderChangeClass()].join(" ")}>
        <Swiper
          // install Swiper modules
          key={text}
          modules={[Navigation]}
          spaceBetween={15}
          breakpoints={{
            1024: {
              spaceBetween: 20,
            },
          }}
          slidesPerView={"auto"}
          navigation
          scrollbar={{ draggable: true }}
          onSlideChange={swiper => {
            setSlideChange(text)
            setIsBegginer(swiper.isBeginning)
          }}
        >
          {children?.length > 0 &&
            children.map(preset => {
              if (preset.hidden) {
                return ""
              }

              const isSelected = values[variableId] === preset.value
              const isDisabled = !preset.active || preset.disabled

              return (
                <SwiperSlide
                  key={preset.value}
                  className={styles.col}
                  onMouseEnter={() =>
                    !isDisabled ? setHovered(preset.value) : ""
                  }
                  onMouseLeave={() => (!isDisabled ? setHovered(null) : "")}
                  onClick={() =>
                    !isDisabled ? handleSelect(preset.value) : ""
                  }
                >
                  <Card
                    hoverd={isHovered(preset.value)}
                    selected={isSelected}
                    disabled={isDisabled}
                    inSlider={true}
                    data={{
                      images: [preset.image ?? ""],
                      title: ` ${preset.text} `,
                    }}
                  />
                </SwiperSlide>
              )
            })}
        </Swiper>
      </div>
    </article>
  )
}
export default PresetDesing
PresetDesing.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }),
  children: PropTypes.node,
}

PresetDesing.defaultProps = {
  data: null,
  children: null,
}
