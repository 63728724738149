import { useEffect, useState, useCallback } from "react"

const useScroll = () => {
  const [y, setY] = useState(window.scrollY)

  const handleNavigation = useCallback(e => {
    const window = e.currentTarget
    setY(window.scrollY)
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation)

    return () => {
      window.removeEventListener("scroll", handleNavigation)
    }
  }, [handleNavigation])

  return y
}
export default useScroll
