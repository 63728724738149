import React from "react"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

import styles from "./NavFooter.module.scss"

const NavFooter = () => {
  const { t, i18n } = useTranslation()

  return (
    <nav className={styles.navFooter}>
      <NavLink to={`/${i18n.language}/terms-of-use`} target="_blank">
        {t("Terms of Use")}
      </NavLink>{" "}
      <span>|</span>
      <NavLink to={`/${i18n.language}/privacy-policy`} target="_blank">
        {t("Privacy Policy")}
      </NavLink>{" "}
      <span>|</span>
      <NavLink to={`/${i18n.language}/cookies-policy`} target="_blank">
        {t("Cookies Policy")}
      </NavLink>
    </nav>
  )
}
export default NavFooter
