import { useTranslation } from "react-i18next"

import Loader from "../../../../Loader/Loader"

import styles from "./LoadingAssets.module.scss"

const LoadingAssets = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.loadingAssets}>
      <div className={styles.top}>
        <p>{t("Loading assets")}</p>
        <Loader />
      </div>
    </div>
  )
}

export default LoadingAssets
