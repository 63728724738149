import { getAreaSequence } from "./areas"
import ViewClass from "./view"

const getViewSequence = views => {
  if (!views || views.length <= 0) {
    return null
  }

  return Object.keys(views)
}

const getActiveViews = views => {
  const activeViews = Object.entries(views).filter(view => {
    const [vewId, viewData] = view

    const viewObj = ViewClass(vewId, viewData)

    return viewObj.isActive()
  })

  return Object.fromEntries(activeViews)
}

const getPrevView = (views, viewId) => {
  if (!viewId || !views || views.length <= 0) {
    return null
  }

  const activeViews = getActiveViews(views)

  const viewSequence = getAreaSequence(activeViews)

  const position = viewSequence.indexOf(viewId)

  if (position === -1 || position - 1 < 0) {
    // Make it circular
    return viewSequence[viewSequence.length - 1]
  }

  return viewSequence[position - 1]
}

const getNextView = (views, viewId) => {
  if (!viewId || !views || views.length <= 0) {
    return null
  }

  const activeViews = getActiveViews(views)

  const viewSequence = getAreaSequence(activeViews)

  const position = viewSequence.indexOf(viewId)

  if (position === -1 || position + 1 > viewSequence.length - 1) {
    // Make it circular
    return viewSequence[0]
  }

  return viewSequence[position + 1]
}

const getDefaultView = views => {
  if (!views || views.length <= 0) {
    return null
  }

  let found = Object.entries(views).find(item => {
    const [, dataItem] = item

    return dataItem.DEFECTO
  })

  if (!found) {
    found = Object.entries(views)[0]
  }

  const [foundId] = found

  return foundId
}

const findView = (views, viewId) => {
  if (!viewId || !views || views.length <= 0) {
    return null
  }

  const found = views[viewId] ?? null

  if (!found) {
    return null
  }

  return { ID: viewId, ...found }
}

export {
  getViewSequence,
  getActiveViews,
  getPrevView,
  getNextView,
  getDefaultView,
  findView,
}
