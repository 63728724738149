import { useContext } from "react"
import { useMediaQuery } from "react-responsive"

import PropTypes from "prop-types"

import useDetectKeyboardOpen from "use-detect-keyboard-open"

import ConfigurationContext from "../../../../context/ConfigurationContext"
import { prepareLayers as prepareFrontViewLayers } from "../../../../helpers-shared/layers/frontViewLayers"
import { prepareLayers as prepareOutsideViewLayers } from "../../../../helpers-shared/layers/outsideViewLayers"
import { prepareLayers as preparePanelViewLayers } from "../../../../helpers-shared/layers/panelViewLayers"
import { getDataAssetsUrl } from "../../../../helpers/general"
import Modal from "../../../Modal/Modal"
import LayerImage from "../../../image/LayerImage"
import RotateWarning from "../RotateWarning/RotateWarning"
import LayerMask from "./LayerMask"
import LoadingAssets from "./LoadingAssets/LoadingAssets"

import style from "./View.module.scss"

const assetsUrl = getDataAssetsUrl()

const View = ({ isLoading }) => {
  const context = useContext(ConfigurationContext)

  const { values, currentView } = context

  const sizes = ["L", "M", "S"]

  const renderLayer = ({
    folder,
    imageName,
    zIndex,
    mask = null,
    overrideRootFolder = null,
    withPlaceholder = false,
  }) => {
    let rootFolder = `${assetsUrl}/images/${values.CCAB_MODELO}/${currentView}`
    if (overrideRootFolder != null) {
      const cleanOverrideRootFolder =
        overrideRootFolder === "/"
          ? overrideRootFolder.replace("/", "")
          : overrideRootFolder
      rootFolder = `${assetsUrl}/images${cleanOverrideRootFolder}`
    }

    const images = sizes.map(size => {
      return `${rootFolder}/${folder}/${size}/${imageName}`
    })

    let layer = <LayerImage images={images} alt="" />

    let maskImage
    if (mask) {
      maskImage = `${assetsUrl}/images/${values.CCAB_MODELO}/${currentView}/${mask.folder}/${mask.imageName}`
    }

    return (
      <LayerMask
        maskImage={maskImage}
        style={{ zIndex }}
        key={`${folder}-${imageName}-${zIndex}`}
        name={`${folder}-${imageName}`}
        withPlaceholder={withPlaceholder}
      >
        {layer}
      </LayerMask>
    )
  }

  const renderLayers = layers => {
    const layerList =
      layers?.length > 0 &&
      layers.map((layer, index) => {
        if (!layer) {
          return ""
        }

        return renderLayer({ ...layer, zIndex: index })
      })

    return layerList
  }

  const renderView = () => {
    let layers

    switch (currentView) {
      case "PANEL":
        layers = preparePanelViewLayers(values)
        return renderLayers(layers)
      case "EXTERIOR":
        layers = prepareOutsideViewLayers(values)
        return renderLayers(layers)
      case "FRONTAL":
      default:
        layers = prepareFrontViewLayers(values)
        return renderLayers(layers)
    }
  }

  const isNarrowLandscape = useMediaQuery({
    query: "(orientation: landscape) and (max-height: 568px)",
  })

  const isKeyboardOpen = useDetectKeyboardOpen()

  if (isNarrowLandscape) {
    return (
      <Modal isOpen={!isKeyboardOpen} canClose={false}>
        <RotateWarning />
      </Modal>
    )
  }

  return (
    <>
      {isLoading && <LoadingAssets />}
      <div
        className={style.view}
        style={{ opacity: `${isLoading ? "0" : "1"}` }}
      >
        {renderView()}
      </div>
    </>
  )
}

export default View

View.propTypes = {
  isLoading: PropTypes.bool,
}

View.default = {
  isLoading: false,
}
