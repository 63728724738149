import { useTranslation } from "react-i18next"

import { FormControl, FormHelperText, TextField } from "@material-ui/core"
import { Field, useFormikContext } from "formik"

import useCountries from "../../hooks/use-countries"
import Loader from "../Loader/Loader"
import SelectForm from "../SelectForm/SelectForm"
import Button from "../buttons/Button"
import ConfigurationErrors from "./ConfigurationErrors/ConfigurationErrors"

import styles from "./RequestDataForm.module.scss"
import "./RequestDataForm.scss"

const RequestDataForm = () => {
  const { t } = useTranslation()
  const formik = useFormikContext()
  const { handleSubmit, isSubmitting, errors } = formik

  const { countriesOptions, getProvincesOptions } = useCountries()

  const provincesOptions = getProvincesOptions(
    formik.values.personal_data.country
  )

  const renderProvinceField = () => {
    if (!provincesOptions || provincesOptions.length <= 0) {
      return (
        <TextField
          className={styles.input}
          id="province"
          label={t("formData.Province")}
          variant="filled"
          name="personal_data.province"
          value={formik.values.personal_data.province}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.personal_data?.province &&
            Boolean(formik.errors.personal_data?.province)
          }
          helperText={
            formik.touched.personal_data?.province &&
            formik.errors.personal_data?.province
          }
          required={true}
        />
      )
    }

    return (
      <FormControl className={styles.input}>
        <Field
          name="personal_data.province"
          component={SelectForm}
          placeholder={t("formData.Province")}
          options={provincesOptions}
          required={true}
        />
        {formik.touched.personal_data?.province &&
          formik.errors.personal_data?.province && (
            <FormHelperText variant={"filled"} required={true} error={true}>
              {formik.errors.personal_data?.province}
            </FormHelperText>
          )}
      </FormControl>
    )
  }

  return (
    <div className={styles.content}>
      <h2 className={styles.h2}>{t("formData.title")}</h2>
      <ConfigurationErrors errors={errors} />
      <div className={styles.contentTop}>
        <div className={styles.col}>
          <TextField
            className={styles.input}
            id="name"
            name="personal_data.first_name"
            label={t("formData.name")}
            variant="filled"
            value={formik.values.personal_data.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.personal_data?.first_name &&
              Boolean(formik.errors.personal_data?.first_name)
            }
            helperText={
              formik.touched.personal_data?.first_name &&
              formik.errors.personal_data?.first_name
            }
            required={true}
          />
        </div>
        <div className={styles.col}>
          <TextField
            className={styles.input}
            id="lastName"
            label={t("formData.LastName")}
            variant="filled"
            name="personal_data.last_name"
            value={formik.values.personal_data.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.personal_data?.last_name &&
              Boolean(formik.errors.personal_data?.last_name)
            }
            helperText={
              formik.touched.personal_data?.last_name &&
              formik.errors.personal_data?.last_name
            }
            required={true}
          />
        </div>
        <div className={styles.col}>
          <TextField
            className={styles.input}
            id="empresa"
            label={t("formData.Company")}
            variant="filled"
            name="personal_data.company"
            value={formik.values.personal_data.company}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.personal_data?.company &&
              Boolean(formik.errors.personal_data?.company)
            }
            helperText={
              formik.touched.personal_data?.company &&
              formik.errors.personal_data?.company
            }
          />
        </div>
        <div className={styles.col}>
          <FormControl className={styles.input}>
            <Field
              name="personal_data.country"
              component={SelectForm}
              placeholder={t("formData.Country")}
              options={countriesOptions}
              required={true}
              onAfterChange={() =>
                formik.setFieldValue("personal_data.province", "", true)
              }
            />
            {formik.touched.personal_data?.country &&
              formik.errors.personal_data?.country && (
                <FormHelperText variant={"filled"} required={true} error={true}>
                  {formik.errors.personal_data?.country}
                </FormHelperText>
              )}
          </FormControl>
        </div>
        <div className={styles.col}>{renderProvinceField()}</div>
        <div className={styles.col}>
          <TextField
            className={styles.input}
            id="email"
            label={t("formData.email")}
            variant="filled"
            name="personal_data.email"
            value={formik.values.personal_data.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.personal_data?.email &&
              Boolean(formik.errors.personal_data?.email)
            }
            helperText={
              formik.touched.personal_data?.email &&
              formik.errors.personal_data?.email
            }
            required={true}
            type={"email"}
          />
        </div>
        <div className={styles.col}>
          <TextField
            className={styles.input}
            id="phone"
            label={t("formData.Phone")}
            variant="filled"
            name="personal_data.phone"
            value={formik.values.personal_data.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.personal_data?.phone &&
              Boolean(formik.errors.personal_data?.phone)
            }
            helperText={
              formik.touched.personal_data?.phone &&
              formik.errors.personal_data?.phone
            }
            type={"tel"}
          />
        </div>
      </div>
      <div className={styles.contentBottom}>
        <div className={[styles.col, styles.consulta].join(" ")}>
          <p
            className={styles.textoLabel}
            dangerouslySetInnerHTML={{ __html: t("formData.sendFile") }}
          />

          <div
            className={styles.contentOptions}
            role="group"
            aria-labelledby="options"
          >
            <label
              htmlFor="personal_data.file_send"
              className={[
                styles.label,
                formik.values.personal_data.file_send === "yes"
                  ? styles.checked
                  : " ",
              ].join(" ")}
            >
              <span
                className={[styles.checkmark, styles.radio].join(" ")}
              ></span>
              <span className={styles.labeltext}>{t("formData.Yes")}</span>
              <Field
                className={styles.input}
                type="radio"
                value="yes"
                name="personal_data.file_send"
              />
            </label>
            <label
              htmlFor="personal_data.file_send"
              className={[
                styles.label,
                formik.values.personal_data.file_send === "no"
                  ? styles.checked
                  : " ",
              ].join(" ")}
            >
              <span
                className={[styles.checkmark, styles.radio].join(" ")}
              ></span>
              <span className={styles.labeltext}>{t("formData.No")}</span>
              <Field
                className={styles.input}
                type="radio"
                value="no"
                name="personal_data.file_send"
              />
            </label>
          </div>
        </div>
        <div className={styles.col}>
          <TextField
            variant="filled"
            multiline
            minRows={6}
            aria-label={t("formData.querys")}
            placeholder={t("formData.querys")}
            className={styles.textarea}
            name="personal_data.observations"
            value={formik.values.personal_data.observations}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.personal_data?.observations &&
              Boolean(formik.errors.personal_data?.observations)
            }
            helperText={
              formik.touched.personal_data?.observations &&
              formik.errors.personal_data?.observations
            }
          />
        </div>
        <div className={[styles.col, styles.consulta].join(" ")}>
          <div className={[styles.contentOptions, styles.column].join(" ")}>
            <label
              className={[
                styles.label,
                formik.values.personal_data.personal_data_rights
                  ? styles.checked
                  : " ",
              ].join(" ")}
            >
              <span className={[styles.checkmark].join(" ")}></span>
              <span className={styles.labeltext}>
                {t("formData.privacityText")} *
                {formik.touched.personal_data?.personal_data_rights &&
                  formik.errors.personal_data?.personal_data_rights && (
                    <FormHelperText error={true}>
                      {formik.errors.personal_data?.personal_data_rights}
                    </FormHelperText>
                  )}
              </span>
              <Field
                className={styles.input}
                type="checkbox"
                name="personal_data.personal_data_rights"
                required={true}
              />
            </label>

            <label
              className={[
                styles.label,
                formik.values.personal_data.send_more_information === true
                  ? styles.checked
                  : " ",
              ].join(" ")}
            >
              <span className={[styles.checkmark].join(" ")}></span>
              <span className={styles.labeltext}>
                {t("formData.MoreInformation")}
              </span>
              <Field
                className={styles.input}
                type="checkbox"
                name="personal_data.send_more_information"
              />
            </label>
          </div>
        </div>
        <div className={styles.col}>
          <Button
            onClick={handleSubmit}
            primary={false}
            disabled={isSubmitting ? true : false}
          >
            {t("formData.Send")}
          </Button>
          {isSubmitting && (
            <div className={styles.submitting}>
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default RequestDataForm
