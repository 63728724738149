import React from "react"
import { Link } from "react-router-dom"

import PropTypes from "prop-types"

import styles from "./LinkButton.module.scss"

const LinkButton = ({ to, children }) => {
  return (
    <Link className={styles.button} to={to}>
      {children}
    </Link>
  )
}

export default LinkButton
LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
}

LinkButton.defaultProps = {
  to: null,
  children: null,
}
