import React from "react"
import { useTranslation } from "react-i18next"

import { Button } from "../buttons/Button"

import styles from "./WelcomeMessage.module.scss"

const WelcomeMessage = ({ onButtonClick }) => {
  const { t } = useTranslation()

  return (
    <>
      <h1
        className={styles.h1}
        dangerouslySetInnerHTML={{ __html: t("bienvenido.title") }}
      />

      <p
        className={styles.p}
        dangerouslySetInnerHTML={{ __html: t("bienvenido.description") }}
      />

      <div className={styles.button}>
        {" "}
        <Button onClick={onButtonClick}>{t("bienvenido.cta")}</Button>
      </div>
    </>
  )
}

export default WelcomeMessage
