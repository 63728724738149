const AreaClass = function (id, data) {
  const obj = Object.create(data)
  obj.ID = id

  function getId() {
    return obj.ID
  }

  function getName(locale) {
    if (!obj.NOMBRE || !obj.ID) {
      return null
    }

    const name = obj.NOMBRE?.[locale] ?? obj.ID

    return name
  }

  function getDescription(locale) {
    if (!obj.DESCRIPCION) {
      return null
    }

    const description = obj.DESCRIPCION?.[locale] ?? ""

    return description
  }

  function getImage(assetsUrl = "") {
    if (!obj.IMAGEN) {
      return ""
    }

    return `${assetsUrl}${obj.IMAGEN}`
  }

  function isFirstSteps() {
    if (obj.VISTA_DEFECTO !== "") {
      return false
    }

    return true
  }

  function isConfigurator() {
    if (obj.VISTA_DEFECTO === "") {
      return false
    }

    return true
  }

  function isHomeSteps() {
    if (obj.VISTA_DEFECTO !== "") {
      return false
    }

    return true
  }

  function getHijos() {
    const hijosEntries = Object.entries(obj.HIJOS)

    if (hijosEntries.length <= 0) {
      return null
    }

    return hijosEntries
  }

  function getVariables() {
    return obj.VARIABLES
  }

  function getFirstVariable() {
    const variableEntries = Object.entries(obj.VARIABLES)

    if (!obj.VARIABLES || variableEntries <= 0) {
      return null
    }

    return variableEntries[0]
  }

  function getDefaultView() {
    return obj.VISTA_DEFECTO
  }

  function displayInTab() {
    return obj.EN_TAB
  }

  function isActive() {
    return obj.ACTIVO ?? true
  }

  return {
    getId,
    getName,
    getImage,
    getDescription,
    isFirstStepsArea: isFirstSteps,
    isConfiguratorArea: isConfigurator,
    isHomeStepsArea: isHomeSteps,
    getHijos,
    getVariables,
    getFirstVariable,
    getDefaultView,
    displayInTab,
    isActive,
  }
}

export default AreaClass
