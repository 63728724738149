import { useTranslation } from "react-i18next"

import LegalPageContent from "../components/LegalPageContent/LegalPageContent"
import SEO from "../components/SEO/SEO"

const PrivacyPolicy = () => {
  const { t } = useTranslation()

  const title = t("Privacy Policy")

  return (
    <LegalPageContent>
      <SEO title={title} description={title} />
      <h1>{title}</h1>
      <div dangerouslySetInnerHTML={{ __html: t("privacypolicyText") }}></div>
    </LegalPageContent>
  )
}

export default PrivacyPolicy
