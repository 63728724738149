import { useState } from "react"

import PropTypes from "prop-types"

import Modal from "../../components/Modal/Modal"

import style from "./IconInfo.module.scss"

const IconInfo = info => {
  const [modalIsOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      <button
        type="button"
        className={style.iconInfo}
        onClick={() => setIsOpen(true)}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle r="10" transform="matrix(-1 0 0 1 10 10)" fill="black" />
          <path
            d="M9 8H11V15H9V8ZM10 6.98984C9.70447 6.98984 9.46392 6.89454 9.27835 6.70394C9.09278 6.51334 9 6.277 9 5.99492C9 5.71283 9.09278 5.47649 9.27835 5.2859C9.46392 5.0953 9.70447 5 10 5C10.2955 5 10.5361 5.09149 10.7216 5.27446C10.9072 5.45743 11 5.68615 11 5.96061C11 6.25794 10.9072 6.50572 10.7216 6.70394C10.5361 6.89454 10.2955 6.98984 10 6.98984Z"
            fill="white"
          />
        </svg>
      </button>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <p> {info.info}</p>
      </Modal>
    </>
  )
}

export default IconInfo
IconInfo.propTypes = {
  info: PropTypes.node.isRequired,
}
