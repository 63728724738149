import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import HomeSteps from "../components/HomeSteps/HomeSteps"
import Modal from "../components/Modal/Modal"
import SEO from "../components/SEO/SEO"
import WelcomeMessage from "../components/WelcomeMessage/WelcomeMessage"
import { AppContext } from "../context/AppContext"
import { getVariableOptionImages } from "../helpers-shared/configuration/variables"
import {
  getFirstActiveHomeStepNumber,
  getHomeStepsAreas,
} from "../helpers/configuration/areas"
import { getCmsAssetsUrl, stripHtml } from "../helpers/general"
import usePreloadImages from "../hooks/use-preload-images"

const assetsUrl = getCmsAssetsUrl()

const Home = () => {
  const { t, i18n } = useTranslation()
  const { state } = useLocation()

  const canShowWelcome = state?.canShowWelcome ?? true

  const [modalIsOpen, setIsOpen] = useState(canShowWelcome)

  const { store } = useContext(AppContext)

  const variableOptionImages = getVariableOptionImages(store.variables, {
    locale: i18n.language,
    assetsUrl,
  })
  usePreloadImages(variableOptionImages)

  function closeModal() {
    setIsOpen(false)
  }

  const metaTitle = stripHtml(t("bienvenido.title"))
  const metaDescription = stripHtml(t("bienvenido.description"))

  const homeSteps = getHomeStepsAreas(store.categories)

  if (!homeSteps) {
    return ""
  }

  const startStep = getFirstActiveHomeStepNumber(homeSteps)

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} withSuffix={false} />
      <HomeSteps startStep={startStep} />

      {canShowWelcome && (
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
          <WelcomeMessage onButtonClick={closeModal} />
        </Modal>
      )}
    </>
  )
}

export default Home
