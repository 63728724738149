import i18n from "i18next"

const validatePersonalData = values => {
  const { t } = i18n

  let errors = {}

  const personalDataErrors = {}

  if (!values.personal_data.first_name) {
    personalDataErrors.first_name = t("Required field.")
  }

  if (!values.personal_data.last_name) {
    personalDataErrors.last_name = t("Required field.")
  }

  if (!values.personal_data.email) {
    personalDataErrors.email = t("Required field.")
  }

  if (values.personal_data.email && !isValidEmail(values.personal_data.email)) {
    personalDataErrors.email = t("Email is invalid.")
  }

  if (!values.personal_data.country) {
    personalDataErrors.country = t("Required field.")
  }

  if (!values.personal_data.province) {
    personalDataErrors.province = t("Required field.")
  }

  if (!values.personal_data.personal_data_rights) {
    personalDataErrors.personal_data_rights = t("Required field.")
  }

  if (Object.keys(personalDataErrors).length > 0) {
    errors = { personal_data: { ...personalDataErrors } }
  }

  return errors
}

export default validatePersonalData

const isValidEmail = value => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
}
