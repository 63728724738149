import i18n from "i18next"

const validateEspejo = values => {
  const { t } = i18n

  const errors = {}

  if (
    values.CCAB_ACCESIBILIDAD === "EN-81-70" &&
    values.CCAB_EMBARQUE === "0" &&
    (!values.CCAB_ESPEJO_PARED || values.CCAB_ESPEJO_PARED.length <= 0)
  ) {
    errors.CCAB_ESPEJO_PARED = t(
      "To comply with the EN 81-70 standard, the cabin must have a mirror."
    )
  }

  if (
    values.CCAB_ESPEJO_PARED &&
    values.CCAB_ESPEJO_PARED.length > 0 &&
    !values.CCAB_ESPEJO_ACABADO
  ) {
    errors.CCAB_ESPEJO_ACABADO = t("Please select an option.")
  }

  if (
    values.CCAB_ESPEJO_PARED &&
    values.CCAB_ESPEJO_PARED.length > 0 &&
    !values.CCAB_ESPEJO_MODELO
  ) {
    errors.CCAB_ESPEJO_MODELO = t("Please select an option.")
  }

  return errors
}

export default validateEspejo
