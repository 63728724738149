import { useTranslation } from "react-i18next"

import Booleano from "../fields/Booleano/Booleano"

import style from "../Area/Area.module.scss"

const AllWallsSameControl = () => {
  const { t } = useTranslation()

  return (
    <div className={style.variable}>
      <Booleano
        label={t("All the walls with same material")}
        name="all_walls_same"
      />
    </div>
  )
}

export default AllWallsSameControl
