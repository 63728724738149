// import { useMediaQuery } from "react-responsive"
import PropTypes from "prop-types"

// import StepCounter from "../StepCounter/StepCounter"
import styles from "./StepContainer.module.scss"

const StepContainer = ({ message, children }) => {
  // const MovilView = ({ children }) => {
  //   const isMobile = useMediaQuery({ query: "(min-width: 580px)" })
  //   return isMobile ? children : null
  // }

  return (
    <div className={styles.stepContainer}>
      {/*<MovilView>*/}
      {/*  <StepCounter />*/}
      {/*</MovilView>*/}

      <p className={styles.stepMessage}>{message}</p>

      {children}
    </div>
  )
}

export default StepContainer

StepContainer.propTypes = {
  children: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
}

StepContainer.defaultProps = {
  children: null,
  message: null,
}
