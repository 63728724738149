import { useState } from "react"
import Select, { components } from "react-select"

import styles from "./SelectForm.module.scss"

const SelectForm = props => {
  const {
    field, // { name, value, onChange, onBlur }
    form, // touched, error, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...otherProps
  } = props

  const {
    placeholder,
    options,
    required,
    onAfterChange = () => {},
  } = otherProps

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleChange = option => {
    const { value } = option

    form.setFieldValue(field.name, value, true)

    onAfterChange()
  }

  const handleBlur = (event, fieldName) => {
    form.setFieldTouched(fieldName, true)
  }

  const CaretDownIcon = () => {
    return (
      <span className={[styles.arrow].join(" ")}>
        <svg
          className={styles.arrowLeft}
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7 7L1 1" stroke="#000000" strokeWidth="2"></path>
        </svg>
        <svg
          className={styles.arrowRight}
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7 1L1 7" stroke="#000000" strokeWidth="2"></path>
        </svg>
      </span>
    )
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    )
  }

  const onMenuOpen = () => {
    setIsMenuOpen(true)
  }
  const onMenuClose = () => setIsMenuOpen(false)

  const selectedOption = options.find(o => o.value === field.value)

  return (
    <Select
      name={field.name}
      placeholder={`${placeholder} ${required ? "*" : ""}`}
      components={{ DropdownIndicator }}
      value={selectedOption ? selectedOption : ""}
      onChange={handleChange}
      onBlur={event => handleBlur(event, field.name)}
      options={options}
      className={[styles.select, isMenuOpen ? styles.menuOpen : " "].join(" ")}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      required={required}
    />
  )
}

export default SelectForm
