import { useMediaQuery } from "react-responsive"

import PropTypes from "prop-types"

import { Field, useFormikContext } from "formik"

import { groupOptions } from "../../../../helpers-shared/configuration/variableOptions"
import {
  getValueForCheckbox,
  updateAllDependentValues,
} from "../../../../helpers/configuration/resets/resetDependencies"
import Tooltip from "../../../Tooltip/Tooltip"
import ErrorMessage from "../ErrorMessage/ErrorMessage"

import style from "./MuestraCircular.module.scss"

const MuestraCircular = props => {
  const { title, name, options, multiselect } = props

  const formik = useFormikContext()
  const { values, errors, handleChange, setValues } = formik

  const handleChangeField = event => {
    handleChange(event)

    const value = multiselect
      ? getValueForCheckbox(event, values)
      : event.target.value

    setValues(updateAllDependentValues(name, value, formik))
  }

  const fieldValue = values[name]
  const isMobile = useMediaQuery({ query: "(min-width: 768px)" })
  const Mobile = ({ children }) => {
    return isMobile ? children : null
  }

  const Small = ({ children }) => {
    const isSmall = useMediaQuery({ query: "(max-width: 767px)" })
    return isSmall ? children : null
  }

  const getIsChecked = value => {
    if (!value) {
      return false
    }

    if (multiselect) {
      return fieldValue.includes(value)
    }

    return fieldValue === value
  }

  if (options?.length > 0 && options.every(o => o.hidden)) {
    return ""
  }

  const getSelectedOptionName = group => {
    if (!group || !group.children || group.children.length <= 0) {
      return null
    }

    const selectedValue = values[name]
    const found = group.children.find(i => i.value === selectedValue)

    if (!found) {
      return null
    }

    return found.text ?? ""
  }

  const renderGroup = group => {
    if (group.children?.length > 0 && group.children.every(o => o.hidden)) {
      return ""
    }

    const selectedOptionName = getSelectedOptionName(group)

    return (
      <>
        <div
          className={[
            style.group,
            !group.text ? style.noTitleGroup : " ",
            selectedOptionName ? style.selectedGroup : " ",
          ].join(" ")}
        >
          {group.text && <p className={style.titleField}>{group.text}</p>}
          {selectedOptionName && (
            <Small>
              <p className={style.seleccionado}>{selectedOptionName}</p>
            </Small>
          )}
          {group.children?.length > 0 && (
            <ul
              className={[
                style.listOptions,
                !title && !group.text ? style.noTitles : " ",
              ].join(" ")}
            >
              {group.children.map(childOption => {
                const {
                  text: optionText,
                  value: optionValue,
                  description: optionDescription,
                  disabled: optionDisabled = false,
                  hidden: optionHidden = false,
                  image: optionImage,
                } = childOption

                if (optionHidden) {
                  return ""
                }

                const isChecked = getIsChecked(optionValue)
                const isDisabled = !childOption.active || optionDisabled

                return (
                  <li key={`field-${name}-option-${optionText}-${optionValue}`}>
                    <label
                      className={[
                        style.label,
                        isDisabled ? style.disabled : " ",
                        isChecked ? style.checked : " ",
                      ].join(" ")}
                      //  data-tip={optionDescription}
                      data-for={`field-${name}-option-${optionValue}`}
                      data-tip={optionDescription}
                    >
                      <span className={style.contentImg}>
                        <span className={style.subContent}>
                          <span className={style.figure}></span>
                          <span className={style.bordercontentImg}>
                            <img
                              src={optionImage}
                              alt=""
                              style={{ borderRadius: "50%" }}
                            />
                          </span>
                          <Tooltip
                            place="top"
                            identificador={`field-${name}-option-${optionValue}`}
                          />
                        </span>
                      </span>

                      <Mobile>
                        <span className={style.text}>{optionText}</span>
                      </Mobile>

                      {multiselect ? (
                        <Field
                          className={style.input}
                          type="checkbox"
                          name={name}
                          value={optionValue}
                          disabled={isDisabled}
                          onChange={handleChangeField}
                        />
                      ) : (
                        <Field
                          type="radio"
                          className={style.input}
                          name={name}
                          value={optionValue}
                          disabled={isDisabled}
                          onChange={handleChangeField}
                        />
                      )}
                    </label>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </>
    )
  }

  const optionGroups = groupOptions(options)

  if (!options || options.length <= 0) {
    return ""
  }

  if (optionGroups.length === 1) {
    return (
      <>
        {title && <p className={style.titleGroup}>{title}</p>}
        {errors[name] && (
          <div
            className={[style.errorMessage, title ? style.withTitle : " "].join(
              " "
            )}
          >
            <ErrorMessage name={name} />
          </div>
        )}
        {renderGroup(optionGroups[0])}
      </>
    )
  }

  return (
    <>
      {title && <p className={style.titleGroup}>{title}</p>}
      {errors[name] && (
        <div
          className={[style.errorMessage, title ? style.withTitle : " "].join(
            " "
          )}
        >
          <ErrorMessage name={name} />
        </div>
      )}
      {optionGroups.length > 0 && (
        <ul className={[style.contentGroups].join(" ")}>
          {optionGroups.map((group, index) => (
            <li
              key={`variable-${name}-group-${group.text ?? index}`}
              className={style.grupo}
            >
              {renderGroup(group)}
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

export default MuestraCircular

MuestraCircular.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
      image: PropTypes.string,
      disabled: PropTypes.bool,
      hidden: PropTypes.bool,
      isGroupTitle: PropTypes.bool,
    })
  ),
  multiselect: PropTypes.bool,
}

MuestraCircular.defaultProps = {
  title: "",
  name: "",
  options: [],
  multiselect: false,
}
