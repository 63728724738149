import VariableClass from "../../helpers-shared/configuration/variable"
import VariableOptionClass from "../../helpers-shared/configuration/variableOption"
import initialValues from "../../helpers/configuration/initial-values"
import { resetValuesByBoardingType } from "./general"
import { recalculateValues } from "./resets/resetDependencies"

class Configuration {
  #params = null
  #store = null
  #mode = 'standard'

  /**
   * Class Constructor.
   * @param params
   * @param store
   * @param mode
   */
  constructor(params = null, store = null, mode = 'standard') {
    this.#params = params
    this.#store = store
    this.#mode = this.#isValidMode(mode) ? mode : 'standard'
  }

  /**
   * Get the initial params of the configuration.
   * @returns {{}|null}
   */
  getParams() {
    return this.#params
  }

  /**
   * Set the initial params of the configuration.
   * @param params
   * @returns {Configuration}
   */
  setParams(params) {
    this.#params = params
    return this
  }

  /**
   * Set the flag for the type of configuration.
   * @param mode
   * @returns {Configuration}
   */
  setMode(mode) {
    this.#mode = this.#isValidMode(mode) ? mode : 'standard'
    return this;
  }

  /**
   * Set the app store access.
   * @param store
   * @returns {Configuration}
   */
  setStore(store) {
    this.#store = store
    return this
  }

  /**
   * Get the proccessed configuration values.
   * @returns {{}}
   */
  getValues() {
    if (!this.#params || !this.isValid()) {
      return null
    }

    return this.#mode === 'standard'
      ? this.#getStandardValues()
      : this.#getCustomValues()
  }

  /**
   * Check if the provided mode is valid
   * @param {string} mode
   * @returns bool
   */
  #isValidMode(mode) {
    return ['standard', 'CCO', 'CCR'].includes(mode)
  }

  /**
   * Return the custom proccessed configuration values.
   * @returns {{}}
   */
  #getCustomValues() {
    return Object.assign(
      {},
      ...Object.keys(this.#params).map(property => ({
        [property]:
          typeof this.#params[property] === "string" &&
          this.#params[property].includes("NODEF")
            ? ""
            : this.#params[property],
      }))
    )
  }

  /**
   * Return the standard proccessed configuration values.
   * @returns {{}}
   */
  #getStandardValues() {
    // load default values for selected boarding type and accessibility type
    const boardingId = this.#params.CCAB_EMBARQUE
    const accessibility = this.#params.CCAB_ACCESIBILIDAD ?? "0"
    const defaultValues =
      this.#store.defaults?.[boardingId]?.[accessibility] ?? null

    // load selected preset (predesign) data
    const selectedPresetId = this.#params.CCAB_AMBIENTE
    const presetValues = this.#store.predesigns?.[selectedPresetId] ?? null

    // overrides of the default values for the selected preset (predesign)
    const presetOverrides = presetValues?.["OVERRIDES"]?.[boardingId] ?? null

    // merge values and recalculate by boarding type
    const values = resetValuesByBoardingType(
      {
        ...initialValues,
        ...this.#params,
        ...defaultValues,
        ...presetValues,
        ...presetOverrides,
        all_walls_same: false,
      },
      { boardingTypeId: boardingId }
    )

    return recalculateValues(values, values)
  }

  /**
   * Check if the configuration provided is valid.
   * @returns {boolean}
   */
  isValid() {
    if (this.#mode === 'CCR') {
      return this.#isValidCCR()
    }

    if (this.#mode === 'CCO') {
      return this.#isValidCCO()
    }

    return this.#isValidStandard()
  }

  /**
   * Check if we have access to the given custom proto schema file and
   * if we can decode it's content
   * @returns {boolean}
   */
  #isValidCCO() {
    return this.#params !== null
  }

  /**
   * Check if we have access to the given ccr proto schema file and
   * if we can decode it's content
   * @returns {boolean}
   */
  #isValidCCR() {
    return !(
      !this.#params ||
      !this.#params.hasOwnProperty("CCAB_EMPRESA_LOGO") ||
      !this.#params.hasOwnProperty("CCAB_EMPRESA_NOMBRE") ||
      !this.#params.hasOwnProperty("CCAB_OFERTA")
    )
  }

  /**
   * Check if the standard configuration is valid.
   * @returns {boolean}
   */
  #isValidStandard() {
    return !(
      !this.#isStandardOptionActive("CCAB_MODELO") ||
      !this.#isStandardOptionActive("CCAB_EMBARQUE") ||
      !this.#isStandardOptionActive("CCAB_AMBIENTE") ||
      !this.#store?.predesigns.hasOwnProperty(this.#params.CCAB_AMBIENTE)
    )
  }

  /**
   * Check if, given a standard configuration, the provided option name is active.
   * @param name
   * @returns {boolean}
   */
  #isStandardOptionActive(name) {
    if (
      !this.#params.hasOwnProperty(name) ||
      !this.#store?.variables.hasOwnProperty(name)
    ) {
      return false
    }

    const variable = VariableClass(name, this.#store?.variables[name] ?? null)
    const options = variable.getOptions() || {}

    if (!options.hasOwnProperty(this.#params[name])) {
      return false
    }

    const option = VariableOptionClass(name, options[this.#params[name]])

    return option.isActive()
  }
}

export default Configuration
