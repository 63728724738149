import { useContext } from "react"
import { useLocation, useParams } from "react-router-dom"

import { AppContext } from "../../context/AppContext"
import Configuration from "../../helpers/configuration/Configuration"
import { getFistStepsValues } from "../../helpers/configuration/general"
import Configure from "./Configure"

const ConfigureStandard = () => {
  const params = useParams()
  const { state } = useLocation()
  const { store } = useContext(AppContext)

  // Don't init the values until the predesigns have been loaded
  if (!store.predesigns) {
    return ""
  }

  const firstStepValues = getFistStepsValues(state, params)
  const configuration = new Configuration()
    .setParams(firstStepValues)
    .setStore(store)

  return (
    <Configure
      initialValues={configuration.getValues()}
      valid={configuration.isValid()}
    />
  )
}

export default ConfigureStandard
