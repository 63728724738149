import protobuf from "protobufjs"

class Protobuf {
  #schema = "v1"
  #path = null

  /**
   * Class Constructor.
   */
  constructor(schema) {
    this.#schema = schema || "v1"
    this.#path = this.#getPath()
  }

  /**
   * Check if the proto file exists
   * @returns {boolean}
   */
  exists() {
    return this.#path !== "null"
  }

  /**
   * Decode the given Uint8Array protobuf string and return it as an object.
   * @param string
   * @returns {Promise<{[p: string]: any}|null>}
   */
  async decode(string) {
    if (!this.#path) {
      return null
    }

    const root = await protobuf.load(this.#path)
    const configuration = root.lookupType("Configuration")
    const decoded = this.#tryToDecode(configuration, string)

    if (!decoded) {
      return null
    }

    // replace enum and apply default values
    return configuration.toObject(decoded, {
      defaults: true,
      enums: String,
      longs: String,
      arrays: true,
      objects: true,
    })
  }

  /**
   * Encode the given object into a Uint8Array protobuf string.
   * @param string
   * @returns {Promise<Uint8Array|null>}
   */
  async encode(string) {
    if (!this.#path) {
      return null
    }

    const root = await protobuf.load(this.#path)
    const configuration = root.lookupType("Configuration")
    const message = configuration.fromObject(string)

    return configuration.encode(message).finish()
  }

  /**
   * Check if the given string can be decoded.
   * @param proto
   * @param string
   * @returns {*|null}
   */
  #tryToDecode(proto, string) {
    try {
      return proto.decode(string)
    } catch {
      return null
    }
  }

  /**
   * Based on the given schema get the path to the proto file.
   * @returns {string|null}
   */
  #getPath() {
    try {
      return require(`../../data/protobuf/ccab-with-enums-${
        this.#schema
      }.proto`)
    } catch {
      return null
    }
  }
}

export default Protobuf
