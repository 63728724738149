import { useContext } from "react"

import PropTypes from "prop-types"

import ConfigurationContext from "../../../context/ConfigurationContext"
import useScroll from "../../../hooks/use-Scroll"
import IconInfo from "../../IconInfo/IconInfo"
import Svg from "../../Svg/Svg"

import style from "./AreaHeader.module.scss"

const AreaHeader = ({ title, description, icon }) => {
  const context = useContext(ConfigurationContext)

  const { prevArea, nextArea, goToArea, goToMenu } = context

  const handleGoToPrev = () => {
    goToArea(prevArea)
  }
  const handleGoToNext = () => {
    goToArea(nextArea)
  }
  const y = useScroll()

  return (
    <>
      <h2
        className={[style.areaHeader, y > 2 ? style.scrolling : " "].join(" ")}
      >
        <span className={style.content}>
          <span className={style.sub}>
            <span className={style.left}>
              <button
                className={[style.button, style.listMenu].join(" ")}
                type="button"
                onClick={goToMenu}
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
                    fill="white"
                  />
                  <rect
                    className={style.bocadillo}
                    x="13"
                    y="15"
                    width="14"
                    height="2"
                    fill="black"
                  />
                  <rect
                    className={style.bocadillo}
                    x="13"
                    y="23"
                    width="14"
                    height="2"
                    fill="black"
                  />
                  <rect
                    className={style.bocadillo}
                    x="13"
                    y="19"
                    width="14"
                    height="2"
                    fill="black"
                  />
                </svg>
              </button>
              {icon && (
                <span className={style.contentIcon}>
                  {" "}
                  <Svg src={icon} />
                </span>
              )}
              {title} {description && <IconInfo info={description} />}
            </span>
            <span className={style.navegation}>
              {prevArea && (
                <button
                  className={[style.button, style.prev, style.nav].join(" ")}
                  type="button"
                  onClick={handleGoToPrev}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.77193 5.6985L7.56358 1.90685L5.65672 -2.43792e-06L-0.000130707 5.65685L1.90672 7.56371L1.90674 7.56369L5.65675 11.3137L7.52195 9.44851L3.77193 5.6985Z"
                      fill="black"
                    />
                  </svg>
                </button>
              )}
              {nextArea && (
                <button
                  className={[style.button, style.next, style.nav].join(" ")}
                  type="button"
                  onClick={handleGoToNext}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.22808 6.30149L0.436437 10.0931L2.34329 12L8.00014 6.34313L6.09329 4.43628L6.09328 4.43629L2.34326 0.686279L0.478068 2.55147L4.22808 6.30149Z"
                      fill="black"
                    />
                  </svg>
                </button>
              )}
            </span>
          </span>
        </span>
      </h2>
    </>
  )
}

export default AreaHeader

AreaHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
}

AreaHeader.defaultProps = {
  title: "",
  description: "",
  icon: "",
}
