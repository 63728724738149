const allowedUserNames = [
  "aepelde",
  "azabaleta",
  "elarrea",
  "xmurua",
  "cfernandez",
  "itamayo",
  "amendia",
  "ibakaikoa",
  "psanz",
  "loregi",
  "crodriguezh",
  "ipaz",
  "jjrodriguez",
  "mmorenoh",
  "izaldua",
  "zsalvador",
  "pduarte",
  "mlorenzo",
  "agoni",
  "iorcasitas",
  "pduran",
  "fmateo",
  "ascortazar",
  "jlecheverria",
  "jgabarain",
  "jgonzalezr",
  "pfernandez",
  "agoikoetxea",
  "jconcha",
  "ligarces",
  "agomezf",
  "ecalafel",
  "degea",
  "mschaefter",
  "ietxabe",
  "iargote",
  "gameztoy",
  "scrompton",
  "asanchezci",
  "mhoogeveen",
  "sdias",
  "SDIAS",
  "jpinto",
  "rmlamim",
  "malzueta",
  "asalsamendi",
  "ayarza",
  "AYARZA",
  "pslegers",
  "PSLEGERS",
  "icundin",
  "AORUESAGASTI",
  "IRODRIGUEZG",
  "IFERNANDEZS",
  "CFERNANDEZ",
  "PSANZ",
  "XALBERDI",
  "JMALEJANDRE",
  "MDAZA",
]

export default allowedUserNames
