import { useEffect, useState } from "react"

import PropTypes from "prop-types"

const Svg = ({ src }) => {
  const [svg, setSvg] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isErrored, setIsErrored] = useState(false)

  useEffect(() => {
    fetch(src, {
      headers: {
        "Content-Type": "image/svg+xml",
      },
    })
      .then(response => response.text())
      .then(data => {
        if (data.startsWith("<svg ")) {
          setSvg(svg2jsx(data))
        } else {
          setSvg("")
        }
      })
      .catch(setIsErrored)
      .then(() => setIsLoaded(true))
  }, [src])

  if (!src) {
    return ""
  }

  if (!svg) {
    return <img src={src} alt="" />
  }

  return (
    <span
      className={`svgInline svgInline--${isLoaded ? "loaded" : "loading"} ${
        isErrored ? "svgInline--errored" : ""
      }`}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  )
}

export default Svg

Svg.propTypes = {
  src: PropTypes.string.isRequired,
}

Svg.defaultProps = {
  src: "",
}

const svg2jsx = code => {
  return code
  //.replaceAll("fill-rule", "fillRule")
  //.replaceAll("clip-rule", "clipRule")
}
