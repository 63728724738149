import { useTranslation } from "react-i18next"
import * as ReactModal from "react-modal"

import PropTypes from "prop-types"

import styles from "./Modal.module.scss"

const Modal = ({
  children,
  isOpen,
  onAfterOpen,
  onRequestClose,
  contentLabel,
  large,
}) => {
  const { t } = useTranslation()

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      className={[styles.modal, large ? styles.large : " "].join(" ")}
      overlayClassName={styles.overlay}
      contentLabel={contentLabel}
      parentSelector={() => document.querySelector("#root")}
      appElement={document.querySelector("#root")}
    >
      {onRequestClose && (
        <button className={styles.close} onClick={onRequestClose}>
          {t("close")}
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fillRule="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              className={styles.svgRect}
              width="31"
              height="31"
              rx="15.5"
              fill="#DBE3E6"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.5606 15.5L21.0029 11.0577L19.9422 9.99707L15.5 14.4393L11.0577 9.99707L9.99705 11.0577L14.4393 15.5L9.99701 19.9422L11.0577 21.0029L15.4999 16.5606L19.9422 21.0029L21.0028 19.9422L16.5606 15.5Z"
              fill="black"
            />
          </svg>
        </button>
      )}
      {children}
    </ReactModal>
  )
}

export default Modal

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onAfterOpen: PropTypes.func,
  onRequestClose: PropTypes.func,
  contentLabel: PropTypes.string,
  large: PropTypes.bool,
}

Modal.defaultProps = {
  children: null,
  isOpen: false,
  onAfterOpen: null,
  onRequestClose: null,
  contentLabel: "",
  large: false,
}
