import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"

import { AppContext } from "../../../context/AppContext"
import HomeStepsContext from "../../../context/HomeStepsContext"
import VariableClass from "../../../helpers-shared/configuration/variable"
import { findVariable } from "../../../helpers-shared/configuration/variables"
import AreaClass from "../../../helpers/configuration/area"
import { findArea } from "../../../helpers/configuration/areas"
import { calculateIncompatibilities } from "../../../helpers/configuration/incompatibilities"
import { getCmsAssetsUrl } from "../../../helpers/general"
import CardBoarding from "../../CardBoarding/CardBoarding"
import Modal from "../../Modal/Modal"
import NormativaMessage from "../../NormativaMessage/NormativaMessage"
import StepContainer from "../StepContainer/StepContainer"

import styles from "./Boarding.module.scss"

const assetsUrl = getCmsAssetsUrl()

const Boarding = () => {
  const { i18n } = useTranslation()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [hovered, setHovered] = useState(null)

  const appContext = useContext(AppContext)
  const { store } = appContext

  const context = useContext(HomeStepsContext)
  const { steps, step, nextStep, allAreas, values, setValue } = context

  if (!steps || steps.length <= 0) {
    return ""
  }

  const stepData = steps.find(s => s.number === step)

  const areaId = stepData["areaId"]

  // Data for the current area
  const areaData = findArea(allAreas, areaId)
  const areaObj = AreaClass(areaId, areaData)
  const description = areaObj.getDescription(i18n.language)
  const variable = areaObj.getFirstVariable()

  if (!variable) {
    return null
  }

  const [variableId, variableData] = variable

  const variableObj = VariableClass(variableId, variableData)

  const variablePreparedOptions = variableObj.getPreparedOptions(
    i18n.language,
    { sorted: true, assetsUrl }
  )

  const preparedOptions = calculateIncompatibilities(
    values,
    variableId,
    variablePreparedOptions,
    store.incompatibilities
  )

  const isAccessibilityActive = () => {
    const variableId = "CCAB_ACCESIBILIDAD"
    const variableData = findVariable(store.variables, variableId)

    const variableObj = VariableClass(variableId, variableData)

    return variableObj.isActive()
  }

  const handleSelect = id => {
    setValue({ [variableId]: id })

    if (!isAccessibilityActive()) {
      nextStep()
      return
    }

    setModalIsOpen(true)
  }

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  const handleContinue = () => {
    setModalIsOpen(false)

    nextStep()
  }

  const getHoveredClass = id => {
    if (!hovered) {
      return false
    }
    if (hovered && hovered === id) {
      return false
    }
    return true
  }

  return (
    <StepContainer message={description}>
      {preparedOptions?.length > 0 && (
        <div className={styles.contentBoarding}>
          <div className={styles.boardingTypeGrid}>
            {preparedOptions.map(option => {
              if (option.hidden) {
                return ""
              }

              const isSelected = values[variableId] === option.value
              const isDisabled = !option.active || option.disabled

              return (
                <div
                  className={styles.col}
                  key={option.value}
                  onClick={() =>
                    !isDisabled ? handleSelect(option.value) : ""
                  }
                  onMouseEnter={() =>
                    !isDisabled ? setHovered(option.value) : ""
                  }
                  onMouseLeave={() => (!isDisabled ? setHovered(null) : "")}
                >
                  <CardBoarding
                    hoverd={getHoveredClass(option.value)}
                    selected={isSelected}
                    disabled={isDisabled}
                    data={{
                      images: [option.image ?? ""],
                      title: option.text,
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )}

      <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal}>
        <NormativaMessage onButtonClick={handleContinue} />
      </Modal>
    </StepContainer>
  )
}

export default Boarding
