const locales = {
  en: { enabled: true, default: true, nativeName: "English" },
  es: { enabled: true, default: false, nativeName: "Español" },
  eu: { enabled: true, default: false, nativeName: "Euskara" },
  ca: { enabled: true, default: false, nativeName: "Català" },
  de: { enabled: true, default: false, nativeName: "Deutsch" },
  fr: { enabled: true, default: false, nativeName: "Français" },
  it: { enabled: true, default: false, nativeName: "Italiano" },
  nl: { enabled: true, default: false, nativeName: "Nederlands" },
  no: { enabled: true, default: false, nativeName: "Norsk" },
  pl: { enabled: true, default: false, nativeName: "Polski" },
  pt: { enabled: true, default: false, nativeName: "Português" },
  ru: { enabled: true, default: false, nativeName: "Ру́сский" },
}

export default locales
