import PropTypes from "prop-types"

import { Field, useFormikContext } from "formik"

import {
  getValueForCheckbox,
  updateAllDependentValues,
} from "../../../../helpers/configuration/resets/resetDependencies"
import Svg from "../../../Svg/Svg"
import Tooltip from "../../../Tooltip/Tooltip"
import ErrorMessage from "../ErrorMessage/ErrorMessage"

import styles from "./OpcionesIcono.module.scss"

const OpcionesIcono = props => {
  const { title, name, options, multiselect } = props

  const formik = useFormikContext()
  const { values, errors, setValues, handleChange } = formik

  const handleChangeField = event => {
    handleChange(event)

    const value = multiselect
      ? getValueForCheckbox(event, values)
      : event.target.value

    setValues(updateAllDependentValues(name, value, formik))
  }

  const fieldValue = values[name]

  const getIsChecked = value => {
    if (!value) {
      return false
    }

    if (multiselect) {
      return fieldValue.includes(value)
    }

    return fieldValue === value
  }

  if (!options || options.length <= 0 || options.every(o => o.hidden)) {
    return ""
  }

  return (
    <div className={styles.content}>
      {title && <p className={styles.titulo}>{title}</p>}
      {errors[name] && (
        <div
          className={[styles.errorMessage, title ? styles.withTitle : " "].join(
            " "
          )}
        >
          <ErrorMessage name={name} />
        </div>
      )}
      <ul className={[styles.list, title ? " " : styles.noTitle].join(" ")}>
        {options.map(option => {
          const {
            text: optionText,
            value: optionValue,
            description: optionDescription,
            disabled: optionDisabled = false,
            hidden: optionHidden = false,
            image: optionImage,
          } = option

          if (optionHidden) {
            return ""
          }

          const isChecked = getIsChecked(optionValue)
          const isDisabled = !option.active || optionDisabled

          return (
            <li key={`field-${name}-option-${optionText}-${optionValue}`}>
              <label
                className={[
                  styles.label,
                  isDisabled ? styles.disabled : " ",
                  isChecked ? styles.checked : "",
                ].join(" ")}
                data-for={`field-${name}-option-${optionValue}`}
                data-tip={optionDescription}
              >
                <Tooltip
                  place="top"
                  identificador={`field-${name}-option-${optionValue}`}
                />
                <div className={styles.circleContent}>
                  <Svg src={optionImage} />
                </div>
                <span className={styles.labeltext}>{optionText}</span>
                <span
                  className={[
                    styles.checkmark,
                    !multiselect ? styles.radio : " ",
                  ].join(" ")}
                ></span>
                {multiselect ? (
                  <Field
                    className={styles.inputCheck}
                    type="checkbox"
                    name={name}
                    value={optionValue}
                    disabled={isDisabled}
                    onChange={handleChangeField}
                  />
                ) : (
                  <Field
                    className={[styles.inputCheck]}
                    type="radio"
                    name={name}
                    value={optionValue}
                    disabled={isDisabled}
                    onChange={handleChangeField}
                  />
                )}
              </label>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default OpcionesIcono

OpcionesIcono.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
      image: PropTypes.string,
      disabled: PropTypes.bool,
      hidden: PropTypes.bool,
    })
  ),
  multiselect: PropTypes.bool,
}

OpcionesIcono.defaultProps = {
  title: "",
  name: "",
  options: [],
  multiselect: false,
}
