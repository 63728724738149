/**
 * See WAI-ARIA guidelines:
 * https://www.w3.org/TR/wai-aria-practices-1.2/examples/accordion/accordion.html
 */
import React, { useState } from "react"

import PropTypes from "prop-types"

import Style from "./Accordion.module.scss"

const Accordion = ({ id, title, children, active }) => {
  const [isActive, setIsActive] = useState(active)

  return (
    <div
      className={[Style.accordionItem, isActive ? Style.abierto : " "].join(
        " "
      )}
    >
      <div className={Style.accordionTitle}>
        <button
          type="button"
          className={[Style.button, isActive ? Style.actived : " "].join(" ")}
          onClick={() => setIsActive(!isActive)}
          aria-expanded={isActive}
          aria-controls={`content-${id}`}
          id={id}
        >
          <span className={Style.content}>
            {title}
            <span className={Style.contentArrow}>
              <span className={Style.arrow}>
                <svg
                  className={Style.arrowLeft}
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7 7L1 1" stroke="#000000" strokeWidth="2.7"></path>
                </svg>
                <svg
                  className={Style.arrowRight}
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7 1L1 7" stroke="#000000" strokeWidth="2.7"></path>
                </svg>
              </span>
            </span>
          </span>
        </button>
      </div>
      {isActive && (
        <div
          className={Style.accordionContent}
          id={`content-${id}`}
          role="region"
          aria-labelledby={id}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export default Accordion

Accordion.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
}

Accordion.defaultProps = {
  id: "",
  title: "",
  children: null,
  active: false,
}
