import TagManager from "react-gtm-module"
import { HelmetProvider } from "react-helmet-async"

import AppRouter from "./AppRouter"
import { ContextWrapper } from "./context/AppContext"
import { CasUserContextProvider } from "./context/CasUserContext"

const tagManagerArgs = {
  gtmId: "GTM-PPS2HW",
}

TagManager.initialize(tagManagerArgs)

const App = () => {
  return (
    <CasUserContextProvider>
      <HelmetProvider>
        <ContextWrapper>
          <AppRouter />
        </ContextWrapper>
      </HelmetProvider>
    </CasUserContextProvider>
  )
}

export default App
