import { useMediaQuery } from "react-responsive"

import PropTypes from "prop-types"

import { Field, useFormikContext } from "formik"

import {
  getValueForCheckbox,
  updateAllDependentValues,
} from "../../../../helpers/configuration/resets/resetDependencies"
import Tooltip from "../../../Tooltip/Tooltip"
import ErrorMessage from "../ErrorMessage/ErrorMessage"

import styles from "./MuestraCuadrada.module.scss"

const MuestraCuadrada = props => {
  const { title, name, options, multiselect } = props

  const formik = useFormikContext()
  const { values, errors, handleChange, setValues } = formik

  const handleChangeField = event => {
    handleChange(event)

    const value = multiselect
      ? getValueForCheckbox(event, values)
      : event.target.value

    setValues(updateAllDependentValues(name, value, formik))
  }

  const fieldValue = values[name]

  const isMobile = useMediaQuery({ query: "(min-width: 768px)" })
  const Pc = ({ children }) => {
    return isMobile ? children : null
  }

  const Mobil = ({ children }) => {
    const isSmall = useMediaQuery({ query: "(max-width: 767px)" })
    return isSmall ? children : null
  }
  const getIsChecked = value => {
    if (!value) {
      return false
    }

    if (multiselect) {
      return fieldValue.includes(value)
    }

    return fieldValue === value
  }

  if (!options || options.length <= 0 || options.every(o => o.hidden)) {
    return ""
  }

  const getSelectedOptionName = options => {
    if (!options || options.length <= 0) {
      return null
    }

    const selectedValue = values[name]
    const found = options.find(i => i.value === selectedValue)

    if (!found) {
      return null
    }

    return found.text ?? ""
  }

  const selecterOptionName = getSelectedOptionName(options)

  return (
    <div className={[styles.content, !title ? styles.noTitle : " "].join(" ")}>
      {title && <p className={styles.titleField}>{title}</p>}
      {errors[name] && (
        <div
          className={[styles.errorMessage, title ? styles.withTitle : " "].join(
            " "
          )}
        >
          <ErrorMessage name={name} />
        </div>
      )}
      {selecterOptionName && (
        <Mobil>
          <p className={styles.seleccionado}>{selecterOptionName}</p>
        </Mobil>
      )}
      {options.length > 0 && (
        <ul className={styles.listChecks}>
          {options.map(option => {
            const {
              text: optionText,
              value: optionValue,
              description: optionDescription,
              disabled: optionDisabled = false,
              hidden: optionHidden = false,
              image: optionImage,
            } = option

            if (optionHidden) {
              return ""
            }

            const isChecked = getIsChecked(optionValue)
            const isDisabled = !option.active || optionDisabled

            return (
              <li key={`field-${name}-option-${optionText}-${optionValue}`}>
                <label
                  className={[
                    styles.label,
                    isDisabled ? styles.disabled : " ",
                    isChecked ? styles.checked : " ",
                  ].join(" ")}
                  data-for={`field-${name}-option-${optionValue}`}
                  data-tip={optionDescription}
                >
                  <span className={styles.imgContent}>
                    <img src={optionImage} alt="" />
                  </span>
                  <Pc>
                    {" "}
                    <span className={styles.text}>{optionText}</span>
                  </Pc>
                  <Tooltip
                    place="top"
                    identificador={`field-${name}-option-${optionValue}`}
                  />

                  {multiselect ? (
                    <Field
                      type="checkbox"
                      name={name}
                      value={optionValue}
                      disabled={isDisabled}
                      className={styles.input}
                      onChange={handleChangeField}
                    />
                  ) : (
                    <Field
                      type="radio"
                      name={name}
                      value={optionValue}
                      disabled={isDisabled}
                      className={styles.input}
                      onChange={handleChangeField}
                    />
                  )}
                </label>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default MuestraCuadrada

MuestraCuadrada.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
      image: PropTypes.string,
      disabled: PropTypes.bool,
      hidden: PropTypes.bool,
    })
  ),
  multiselect: PropTypes.bool,
}

MuestraCuadrada.defaultProps = {
  title: "",
  name: "",
  options: [],
  multiselect: false,
}
