import PropTypes from "prop-types"

import { useFormikContext } from "formik"

import style from "./ErrorMessage.module.scss"

const ErrorMessage = props => {
  const { name } = props

  const { errors } = useFormikContext()

  if (!name) {
    return ""
  }

  if (!errors[name]) {
    return ""
  }

  return <p className={style.error}>{errors[name]}</p>
}

export default ErrorMessage

ErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
}

ErrorMessage.defaultProps = {
  name: "",
}
