import React from "react"

import PropTypes from "prop-types"

// import styles from "./Image.module.scss"

//TODO : https://css-tricks.com/preventing-content-reflow-from-lazy-loaded-images/
const Image = ({ alt, className, images, style, on_load, loading }) => {
  if (
    !images ||
    !Array.isArray(images) ||
    images.length <= 0 ||
    images.join("").length === 0
  ) {
    return ""
  }
  return images.length > 1 ? (
    <picture className={className} style={style} onLoad={on_load}>
      {images[3] && <source media="(max-width: 376px)" srcSet={images[3]} />}
      {images[2] && <source media="(max-width: 769px)" srcSet={images[2]} />}
      {images[1] && <source media="(max-width: 1360px)" srcSet={images[1]} />}
      <source media="(min-width: 1361px)" srcSet={images[0]} />
      <img src={images[0]} alt={alt} loading={loading} />
    </picture>
  ) : (
    <img
      loading={loading}
      onLoad={on_load}
      style={style}
      src={images[0]}
      className={className}
      alt={alt}
    />
  )
}

export default Image
Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  images: PropTypes.array,
  style: PropTypes.object,
  loading: PropTypes.func,
  onLoad: PropTypes.func,
}

Image.defaultProps = {
  alt: null,
  images: null,
}
