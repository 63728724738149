import PropTypes from "prop-types"

import { Field, useFormikContext } from "formik"

import { updateAllDependentValues } from "../../../../helpers/configuration/resets/resetDependencies"
import ErrorMessage from "../ErrorMessage/ErrorMessage"

import styles from "./Booleano.module.scss"

const Booleano = props => {
  const { label, name, disabled, active, hidden } = props

  const formik = useFormikContext()
  const { values, handleChange, setValues } = formik

  const handleChangeField = event => {
    handleChange(event)

    const value = event.target.checked

    setValues(updateAllDependentValues(name, value, formik))
  }

  if (hidden) {
    return ""
  }

  const fieldValue = values[name]

  const isChecked = fieldValue === true
  const isDisabled = !active || disabled

  return (
    <div className={styles.content}>
      <label
        className={[
          styles.label,
          isDisabled ? styles.disabled : " ",
          isChecked ? styles.checked : "",
        ].join(" ")}
      >
        <span className={styles.labeltext}> {label}</span>
        <span className={styles.checkmark}></span>
        <Field
          className={styles.inputCheck}
          type="checkbox"
          name={name}
          disabled={isDisabled}
          onChange={handleChangeField}
        />
      </label>

      <ErrorMessage name={name} />
    </div>
  )
}

export default Booleano

Booleano.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  hidden: PropTypes.bool,
}

Booleano.defaultProps = {
  label: "",
  name: "",
  disabled: false,
  active: true,
  hidden: false,
}
