import i18n from "i18next"

const validatePasamanos = values => {
  const { t } = i18n

  const errors = {}

  if (
    values.CCAB_ACCESIBILIDAD === "EN-81-70" &&
    values.CCAB_PANEL_MANDO_POSICION.includes("IZQUIERDA") &&
    ((!values.CCAB_PASAMANO_POSICION &&
      values.CCAB_PASAMANO_POSICION.length <= 0) ||
      !values.CCAB_PASAMANO_POSICION.includes("IZQUIERDA"))
  ) {
    errors.CCAB_PASAMANO_POSICION = t(
      "To comply with the EN 81-70 standard, there must be a handrail on the wall of the panel."
    )
  }

  if (
    values.CCAB_ACCESIBILIDAD === "EN-81-70" &&
    values.CCAB_PANEL_MANDO_POSICION.includes("DERECHA") &&
    ((!values.CCAB_PASAMANO_POSICION &&
      values.CCAB_PASAMANO_POSICION.length <= 0) ||
      !values.CCAB_PASAMANO_POSICION.includes("DERECHA"))
  ) {
    errors.CCAB_PASAMANO_POSICION = t(
      "To comply with the EN 81-70 standard, there must be a handrail on the wall of the panel."
    )
  }

  if (
    values.CCAB_ACCESIBILIDAD === "EN-81-70" &&
    values.CCAB_PANEL_MANDO_POSICION.includes("FRONTAL") &&
    ((!values.CCAB_PASAMANO_POSICION &&
      values.CCAB_PASAMANO_POSICION.length <= 0) ||
      !values.CCAB_PASAMANO_POSICION.includes("FRONTAL"))
  ) {
    errors.CCAB_PASAMANO_POSICION = t(
      "To comply with the EN 81-70 standard, there must be a handrail on the wall of the panel."
    )
  }

  if (
    values.CCAB_PASAMANO_POSICION &&
    values.CCAB_PASAMANO_POSICION.length > 0 &&
    !values.CCAB_PASAMANO_MODELO
  ) {
    errors.CCAB_PASAMANO_MODELO = t("Please select an option.")
  }

  return errors
}

export default validatePasamanos
