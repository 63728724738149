import validateEspejo from "./validateEspejo"
import validateExterior from "./validateExterior"
import validatePanelesMando from "./validatePanelesMando"
import validateParedes from "./validateParedes"
import validatePasamanos from "./validatePasamanos"
import validatePersonalData from "./validatePersonalData"
import validateProtecciones from "./validateProtecciones"
import validatePuerta from "./validatePuerta"
import validateSuelo from "./validateSuelo"
import validateTecho from "./validateTecho"

const validate = values => {
  let errors = {}

  errors = { ...errors, ...validateParedes(values) }

  errors = { ...errors, ...validateTecho(values) }

  errors = { ...errors, ...validateSuelo(values) }

  errors = { ...errors, ...validateEspejo(values) }

  errors = { ...errors, ...validatePuerta(values) }

  errors = { ...errors, ...validatePanelesMando(values) }

  errors = { ...errors, ...validatePasamanos(values) }

  errors = { ...errors, ...validateProtecciones(values) }

  errors = { ...errors, ...validateExterior(values) }

  errors = { ...errors, ...validatePersonalData(values) }

  return errors
}

export default validate
