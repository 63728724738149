import React from "react"
import { Outlet } from "react-router-dom"

import NavFooter from "../../components/NavFooter/NavFooter"
import { getSiteTitle } from "../../helpers/general"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"

import styles from "./GeneralLayout.module.scss"

const GeneralLayout = () => {
  return (
    <div className={styles.content}>
      {/* Main header */}
      <Header
        data={{
          titulo: getSiteTitle(),
        }}
      />

      {/* Main content */}
      <main className={styles.main}>
        <div className={styles.container}>
          <Outlet />
        </div>
      </main>

      {/* Site footer */}
      <Footer areaB={<NavFooter />} />
    </div>
  )
}

export default GeneralLayout
