import React from "react"

import PropTypes from "prop-types"

import styles from "./ListTitle.module.scss"

const ListTitle = ({ children, uppercase, inboarding }) => (
  <h3
    className={[
      styles.h3,
      uppercase ? styles.upper : " ",
      inboarding ? styles.inboarding : " ",
    ].join(" ")}
  >
    {children}
  </h3>
)

export default ListTitle
ListTitle.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * change de text to uppercase
   */
  uppercase: PropTypes.bool,
  inboarding: PropTypes.bool,
}

ListTitle.defaultProps = {
  children: null,
  uppercase: false,
  inboarding: false,
}
