import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useMediaQuery } from "react-responsive"

import { AppContext } from "../../../context/AppContext"
import HomeStepsContext from "../../../context/HomeStepsContext"
import VariableClass from "../../../helpers-shared/configuration/variable"
import VariableOptionClass from "../../../helpers-shared/configuration/variableOption"
import { findOption } from "../../../helpers-shared/configuration/variableOptions"
import { findVariable } from "../../../helpers-shared/configuration/variables"

// import StepCounter from "../StepCounter/StepCounter"
import styles from "./StepsHeader.module.scss"

const StepsHeader = () => {
  const { t, i18n } = useTranslation()

  const context = useContext(HomeStepsContext)
  const appContext = useContext(AppContext)

  const { store } = appContext
  const { step, steps, startStep, goToStep, prevStep, values } = context

  // const stepNumberString = number => {
  //   return ("0" + number).slice(-2)
  // }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ query: "(max-width: 580px)" })
    return isMobile ? children : null
  }
  const PC = ({ children }) => {
    const isPc = useMediaQuery({ query: "(min-width: 580px)" })
    return isPc ? children : null
  }

  const getStepSelectedValue = stepNumber => {
    if (!steps || !values) {
      return null
    }

    const stepObject = steps.find(stepItem => stepItem.number === stepNumber)
    const { key } = stepObject

    const selectedValue = values[key]

    return selectedValue
  }

  const renderStepSelectedValue = stepNumber => {
    const selectedValue = getStepSelectedValue(stepNumber)

    if (!selectedValue) {
      return ""
    }

    const stepObject = steps.find(stepItem => stepItem.number === stepNumber)
    const { key: variableId } = stepObject

    const variableData = findVariable(store.variables, variableId)

    if (!variableData) {
      return ""
    }

    const variableObj = VariableClass(variableId, variableData)
    const options = variableObj.getOptions()

    const optionData = findOption(options, selectedValue)

    if (!optionData) {
      return ""
    }

    const optionObj = VariableOptionClass(selectedValue, optionData)

    return optionObj.getName(i18n.language)
  }

  const renderStepsSelectedValues = () => {
    if (!steps || steps.length <= 0) {
      return null
    }

    const stepValues = steps
      .map(s => {
        if (!s || !s.number) {
          return null
        }
        return { number: s.number, value: renderStepSelectedValue(s.number) }
      })
      .filter(s => s)
      .filter(s => s.value !== null)

    if (stepValues.length <= 0) {
      return ""
    }

    return (
      <ul className={styles.stepsPreselectMobile}>
        <Mobile>
          {stepValues.map(stepValue => {
            return (
              <li
                key={`${stepValue.number}-${stepValue.value}`}
                className={styles.aditional}
              >
                {stepValue.value}
              </li>
            )
          })}
        </Mobile>
      </ul>
    )
  }

  return (
    <>
      <div className={styles.content}>
        {steps?.length > 0 && (
          <ul className={styles.stepsPreselect}>
            {steps.map(s => {
              if (!s) {
                return ""
              }

              const { name, number, canVisit } = s

              const className = step === number ? styles.active : ""

              const canClick = number < step && canVisit

              return (
                <li className={className} key={`step-${number}-${name}`}>
                  <button
                    type="button"
                    disabled={!canClick}
                    onClick={() => (canClick ? goToStep(number) : null)}
                  >
                    {/*{stepNumberString(number)} |*/} {name}
                    <PC>
                      <span className={styles.aditional}>
                        {renderStepSelectedValue(number)}
                      </span>
                    </PC>
                  </button>
                </li>
              )
            })}
          </ul>
        )}
        {/*<Mobile>*/}
        {/*  <StepCounter />*/}
        {/*</Mobile>*/}
      </div>
      <div className={styles.block}>
        <Mobile>{renderStepsSelectedValues()}</Mobile>

        {step > startStep && (
          <div className={styles.contentBack}>
            <button type="button" className={styles.back} onClick={prevStep}>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.53033 6.53039L5.53033 11.5304L6.59099 10.4697L2.87132 6.75006L11.0606 6.75024L11.0606 5.25024L2.87132 5.25006L6.59099 1.53039L5.53033 0.469727L0.53033 5.46973L0 6.00006L0.53033 6.53039Z"
                  fill="black"
                />
              </svg>{" "}
              {t("Back")}
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default StepsHeader
