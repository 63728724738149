import i18n from "i18next"

const validateExterior = values => {
  const { t } = i18n

  const errors = {}

  if (!values.CCAB_BOTONERA_PARED) {
    errors.CCAB_BOTONERA_PARED = t("Please select an option.")
  }

  if (!values.CCAB_BOTONERA_POSICION) {
    errors.CCAB_BOTONERA_POSICION = t("Please select an option.")
  }

  if (!values.CCAB_BOTONERA_MARCO) {
    errors.CCAB_BOTONERA_MARCO = t("Please select an option.")
  }

  if (!values.CCAB_BOTONERA_PULSADOR) {
    errors.CCAB_BOTONERA_PULSADOR = t("Please select an option.")
  }

  if (values.CCAB_SENAL_PISO && !values.CCAB_SENAL_PISO_UBICACION) {
    errors.CCAB_SENAL_PISO_UBICACION = t("Please select an option.")
  }

  if (values.CCAB_SENAL_PISO && !values.CCAB_SENAL_PISO_TIPO) {
    errors.CCAB_SENAL_PISO_TIPO = t("Please select an option.")
  }

  return errors
}

export default validateExterior
