import { getParedSideVariableId } from "../../helpers-shared/configuration/utils"

const getFistStepsValues = (state, params) => {
  if (state?.firstStepsValues) {
    return state.firstStepsValues
  }

  if (params) {
    return params
  }

  return {}
}

const getMainWallId = values => {
  // default (0, 90, 180)
  let mainWallId = "CCAB_PARED_IZDA_ACABADO"

  if (values.CCAB_EMBARQUE === "270") {
    mainWallId = "CCAB_PARED_FRONT_ACABADO"
  }

  return mainWallId
}

/**
 * Remove variable value from side
 *
 * @param side
 * @param value
 * @returns {*}
 */
const removeFromSide = (side, value) => {
  return value.filter(pared => pared !== side)
}

const removeDuplicates = list => {
  return [...new Set(list)]
}

const resetValuesByBoardingType = (values, options = {}) => {
  const { boardingTypeId } = options

  switch (boardingTypeId) {
    case "90":
      return resetSideValues("DERECHA", values)
    case "180":
      return resetSideValues("FRONTAL", values)
    case "270":
      return resetSideValues("IZQUIERDA", values)
    case "0":
    default:
      return values
  }
}

const resetSideValues = (side, values) => {
  /**
   * TODO:
   *
   * Reset...
   * - wall material
   * - handrail position
   * - mirror position
   * - panel position
   *
   * Hide...
   * - Bandas de protección
   * - Multiple handrail protecciones
   *
   * Disable...
   * - Wall material field for side
   */

  const paredSideVariableId = getParedSideVariableId(side)

  let newValues = { ...values }

  // Empty the material of the wall on `side`
  newValues[paredSideVariableId] = ""

  // Remove handrail from the wall on `side`
  newValues.CCAB_PASAMANO_POSICION = removeFromSide(
    side,
    newValues.CCAB_PASAMANO_POSICION
  )

  // Remove mirror from the wall on `side`
  newValues.CCAB_ESPEJO_PARED = removeFromSide(
    side,
    newValues.CCAB_ESPEJO_PARED
  )

  // Remove panel from the wall on `side`
  newValues.CCAB_PANEL_MANDO_POSICION = removeFromSide(
    side,
    newValues.CCAB_PANEL_MANDO_POSICION
  )

  return newValues
}

export {
  getFistStepsValues,
  getMainWallId,
  removeFromSide,
  removeDuplicates,
  resetValuesByBoardingType,
  resetSideValues,
}
