import { useTranslation } from "react-i18next"

import allCountries from "../data/all-countries.json"
import provinces from "../data/provinces"

const useCountries = () => {
  const { i18n } = useTranslation()

  const sortedCountries = allCountries.sort((a, b) =>
    a.name[i18n.language] > b.name[i18n.language] ? 1 : -1
  )

  const countriesOptions = sortedCountries.map(c => {
    return { label: c.name[i18n.language], value: c.code }
  })

  const getProvincesOptions = (countryCode = null) => {
    if (!countryCode || !(countryCode in provinces)) {
      return null
    }

    const sortedProvinces = provinces[countryCode].sort((a, b) =>
      parseInt(a.order, 10) > parseInt(b.order, 10) ? 1 : -1
    )

    const countryProvinces = sortedProvinces.map(p => {
      return { label: p.name[i18n.language], value: p.code }
    })

    return countryProvinces
  }

  return { sortedCountries, countriesOptions, getProvincesOptions }
}

export default useCountries
