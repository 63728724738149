import React from "react"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"

import ConfiguratorHeader from "../../components/Configuration/ConfiguratorHeader/ConfiguratorHeader"
import { getSiteTitle } from "../../helpers/general"
import Header from "../Header/Header"

import useCCR from "../../hooks/use-CCR"
import useCustom from "../../hooks/use-Custom"

import styles from "./ConfiguratorLayout.module.scss"

const ConfiguratorLayout = () => {
  const { isCustom } = useCustom()
  const { isCCR } = useCCR()

  const { t } = useTranslation()

  const isViewer = isCustom || isCCR
  const title = isCCR ? t("Orona Viewer") : getSiteTitle(isCustom)

  return (
    <div
      className={[styles.content, isViewer ? styles.inCustom : " "]
        .join(" ")
        .trim()}
    >
      {/* Main header */}
      <Header
        data={{
          titulo: title,
        }}
        inConfiguration={true}
      >
        <ConfiguratorHeader />
      </Header>

      {/* Main content */}
      <main className={styles.main}>
        <Outlet />
      </main>
    </div>
  )
}

export default ConfiguratorLayout
