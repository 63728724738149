import { Children, cloneElement, isValidElement, useRef } from "react"

import PropTypes from "prop-types"

import styles from "./View.module.scss"

const LayerMask = ({ maskImage, style, name, withPlaceholder, children }) => {
  let maskStyle = { ...style }

  if (maskImage) {
    const mask = `url("${maskImage}")`

    maskStyle = {
      ...maskStyle,
      WebkitMaskImage: mask,
      maskImage: mask,
    }
  }

  const refPlaceholder = useRef()

  const removePlaceholder = () => {
    refPlaceholder.current.style.opacity = "0"
  }

  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child) && withPlaceholder) {
      return cloneElement(child, {
        on_load: removePlaceholder,
      })
    }
    return child
  })

  return (
    <div className={styles.layerMask} style={maskStyle} rel={name}>
      {childrenWithProps}
      {withPlaceholder && (
        <div className={styles.layerMaskPlaceholder} ref={refPlaceholder} />
      )}
    </div>
  )
}

export default LayerMask

LayerMask.propTypes = {
  maskImage: PropTypes.string,
  style: PropTypes.object,
  name: PropTypes.string,
  withPlaceholder: PropTypes.bool,
  children: PropTypes.node,
}

LayerMask.defaultProps = {
  maskImage: "",
  style: null,
  name: "",
  withPlaceholder: false,
  childen: null,
}
