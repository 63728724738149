import React, { Suspense, useContext } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import RequireAuth from "./RequireAuth"
import ErrorFallback from "./components/ErrorFallback/ErrorFallback"
import ScrollToTop from "./components/ScrollToTop/ScrollToTop"
import { CasUserContext } from "./context/CasUserContext"
import { getEnabledLanguages } from "./helpers/general"
import ConfiguratorLayout from "./layout/ConfiguratorLayout/ConfiguratorLayout"
import GeneralLayout from "./layout/GeneralLayout/GeneralLayout"
import locales from "./locales"
import Index from "./pages/Index"
import NotAuthorized from "./pages/NotAuthorized"
import NotFound from "./pages/NotFound"
import routes from "./routes"

const AppRouter = () => {
  const casUserContext = useContext(CasUserContext)

  const withAuth = casUserContext.withAuth

  const createRoutes = (language, layout = null, layoutElement = null) => {
    let layoutRoutes = routes

    // Get routes with the desired layout only
    if (layout) {
      layoutRoutes = routes.filter(route => route.layout === layout)
    }

    if (!layoutRoutes || layoutRoutes.length <= 0) {
      return null
    }

    // If layoutElement was not specified, use the general layout by default
    if (!layoutElement) {
      layoutElement = <GeneralLayout />
    }

    return (
      <Route
        path={`/${language}`}
        key={`route-${language}`}
        element={layoutElement}
      >
        {layoutRoutes.map(route => {
          let indexPathProp = {}
          if (route.index) {
            indexPathProp = { index: route.index }
          } else {
            indexPathProp = { path: route.path }
          }

          // Check if we need to authenticate the user before rendering the route
          // This configuration comes from and env var.
          let element = withAuth ? (
            <RequireAuth>{route.component}</RequireAuth>
          ) : (
            route.component
          )

          element = (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              {element}
            </ErrorBoundary>
          )

          return (
            <Route
              {...indexPathProp}
              element={element}
              key={`route-${language}-${route.id}`}
            />
          )
        })}
      </Route>
    )
  }

  const enabledLanguages = getEnabledLanguages(locales)

  const generalLocalizedRoutes =
    enabledLanguages && Object.keys(enabledLanguages)?.length > 0
      ? Object.keys(enabledLanguages).map(locale =>
          createRoutes(locale, "general", <GeneralLayout />)
        )
      : null

  const configuratorLocalizedRoutes =
    enabledLanguages && Object.keys(enabledLanguages)?.length > 0
      ? Object.keys(enabledLanguages).map(locale =>
          createRoutes(locale, "configurator", <ConfiguratorLayout />)
        )
      : null

  const notAuthorizedLocalizedRoute =
    enabledLanguages && Object.keys(enabledLanguages)?.length > 0
      ? Object.keys(enabledLanguages).map(locale => (
          <Route
            path={`/${locale}/not-authorized`}
            element={<NotAuthorized />}
            key={`route-not-authorized-${locale}`}
          />
        ))
      : null

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* Index route just to redirect from / to the correct language route */}
          <Route index element={<Index />} key={`route-index`} />

          {/* Localized routes with "General" layout */}
          {generalLocalizedRoutes}

          {/* Localized routes with "Configurator" layout */}
          {configuratorLocalizedRoutes}

          {/* Not authorized route */}
          <Route element={<GeneralLayout />}>
            {notAuthorizedLocalizedRoute}
          </Route>

          {/* Not found route */}
          <Route element={<GeneralLayout />}>
            <Route path="*" element={<NotFound />} key={`route-not-found`} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  )
}

export default function WrappedApp() {
  return (
    <Suspense fallback="Loading...">
      <AppRouter />
    </Suspense>
  )
}
