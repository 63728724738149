import ReactTooltip from "react-tooltip"

import PropTypes from "prop-types"

import "./Tooltip.scss"

const Tooltip = ({ place, identificador, view }) => {
  return (
    <ReactTooltip
      place={place}
      effect="solid"
      className={"tooltipCustumize " + (view ? view : " ")}
      type="light"
      id={identificador}
    />
  )
}
export default Tooltip
Tooltip.propTypes = {
  place: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  identificador: PropTypes.string,
  view: PropTypes.string,
}
Tooltip.defaultProps = {
  place: "top",
  identificador: null,
  view: null,
}
