const findOption = (options, optionId) => {
  if (!optionId || !options || options.length <= 0) {
    return null
  }

  const found = options[optionId] ?? null

  if (!found) {
    return null
  }

  return found
}

const groupOptions = options => {
  const groups = options
    .filter(item => item.isGroupTitle)
    .map(item => {
      return { ...item, children: [] }
    })

  // If there are no groups, return a single group without title text and options as children
  if (groups.length <= 0) {
    return [{ children: options }]
  }

  // Otherwise, proceed to group options
  const groupsObject = Object.fromEntries(
    groups.map(item => [item.value, item])
  )

  let currentGroup = null
  for (const item of options) {
    if (item.isGroupTitle) {
      currentGroup = item.value
      continue
    }

    if (!currentGroup) {
      continue
    }

    const group = groupsObject[currentGroup]

    group.children.push(item)
  }

  const groupsArray = Object.entries(groupsObject).map(item => item[1])

  return groupsArray
}

export { findOption, groupOptions }
