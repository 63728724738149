import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import styles from "./LinkBack.module.scss"

const LinkBack = () => {
  const { t, i18n } = useTranslation()
  return (
    <div className={styles.contentback}>
      <Link className={styles.back} to={`/${i18n.language}`}>
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.53033 6.53039L5.53033 11.5304L6.59099 10.4697L2.87132 6.75006L11.0606 6.75024L11.0606 5.25024L2.87132 5.25006L6.59099 1.53039L5.53033 0.469727L0.53033 5.46973L0 6.00006L0.53033 6.53039Z"
            fill="black"
          ></path>
        </svg>
        {t("Back")}
      </Link>
    </div>
  )
}

export default LinkBack
